import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { Grid, TextField } from '@mui/material';
import APIService from '../../../../APIService';
import swal from 'sweetalert';

export default function DraftRegulations() {

  const [regulations, setRegulations] = useState([])
  const [description, setDescription] = useState("");
  const [year, setYear] = useState("");
  const [fileEnglishUpload, setFileEnglishUpload] = useState("");
  const [fileMalayalamUpload, setFileMalayalamUpload] = useState("");
  const [fileGazzetUpload, setFileGazzetUpload] = useState("");

  const [regulationId, setRegulationId] = useState("");
  const [isEditing, setIsEditing] = useState(false);


  useEffect(() => {
    APIService.get('draft-regulations').then((response) => {
      setRegulations(response.data);
    });
  }, []);

  const handleFileChange = (e, field = "english") => {
    if (e.target.files) {
      switch (field) {
        case "english":
          setFileEnglishUpload(e.target.files[0]);
          break;
        case "malayalam":
          setFileMalayalamUpload(e.target.files[0]);
          break;
        default:
          setFileGazzetUpload(e.target.files[0]);
          break;
      }
    }
  }


  const changePosition = (direction, itemId) => {

    APIService.post([], `change-position/${direction}/draft-regulations/${itemId}`)
      .then((response) => {
        // console.log(response);
        if (response.success) {
          APIService.get('draft-regulations').then((response) => {
            setRegulations(response.data);
          });
        }
      })
  }


  const handleEdit = (itemId, description, year) => {
    setRegulationId(itemId);
    setIsEditing(true);
    setDescription(description);
    setYear(year);
  };


  const handleDelete = (itemId) => {
    // e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "You want to delete this item?",
      icon: "warning",
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        APIService.delete(`draft-regulations/${itemId}`).then((response) => {
          swal("Success", response.message, "success", {
            buttons: false,
            timer: 2000,
          }).then((value) => {
            window.location.href = "/draft_regulations";
          });
        });
      }
    });
  };



  const handleSubmit = async (e) => {

    e.preventDefault();
    const formData = new FormData();

    formData.append("description", description);
    formData.append("year", year);
    formData.append("english_upload", fileEnglishUpload);
    formData.append("malayalam_upload", fileMalayalamUpload);
    formData.append("gazette_upload", fileGazzetUpload);

    if (isEditing) {
      var response = await APIService.put(formData, `draft-regulations/${regulationId}`);
    } else {
      var response = await APIService.post(formData, "draft-regulations");
    }


    if (response.success) {
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).then((value) => {
        window.location.href = "/draft_regulations";
      });
    } else {
      swal("Failed", response.message, "error");
    }
  }

  return (
    <div>
      <h3 className='heading'>Draft Regulations</h3>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          {/* <Grid item xs={12} sm={6} lg={4}>
          <p id="labels">Si.No</p>
          <TextField
            name="si_no"
            label="Si.no"
            variant="outlined"
            fullWidth
          />
        </Grid> */}
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Year</p>
            <TextField
              type="number"
              name="year"
              label="Year"
              variant="outlined"
              fullWidth
              value={year}
              onChange={e => setYear(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={12} md={8}>
            <p id="labels">Decription</p>
            <TextField
              fullWidth
              id="outlined-multiline-static"
              label="Name"
              multiline
              rows={3}
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Upload English</p>
            <TextField
              name="upload-photo"
              type="file"
              onChange={(e, field) => handleFileChange(e, "english")}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Upload Malayalam</p>
            <TextField
              name="upload-photo"
              type="file"
              onChange={(e, field) => handleFileChange(e, "malayalam")}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Upload Gazette</p>
            <TextField
              name="upload-photo"
              type="file"
              onChange={(e, field) => handleFileChange(e, "gazette")}
            />
          </Grid>
        </Grid>
        <Button style={{ marginTop: 20 }} variant="primary" type="submit">Submit</Button>{' '}
      </form>
      <Table striped bordered hover variant="light">
        <thead>
          <tr>
            <th className='table-no-aling'>Sr.No.</th>
            <th>Decription</th>
            <th>Year</th>
            <th>Edit</th>
            <th>Delete</th>
            <th>Move Up</th>
            <th>Move Down</th>
          </tr>
        </thead>
        <tbody>
          {regulations.map((regulation, index) => (
            <tr key={regulation.id}>
              <td>{index + 1}</td>
              <td>{regulation.description}</td>
              <td>{regulation.year}</td>
              <td><button className='tablebutton'
                onClick={() => handleEdit(regulation.id, regulation.description, regulation.year)}
              >Edit</button></td>
              <td><button className='tablebutton'
                onClick={() => handleDelete(regulation.id)}
              >Delete</button></td>
              <td><button className='move-btn'
                onClick={() => changePosition("up", regulation.id)}
              ><img width="32" height="32" src="https://img.icons8.com/external-kmg-design-glyph-kmg-design/32/external-up-arrow-arrows-kmg-design-glyph-kmg-design.png" alt="external-up-arrow-arrows-kmg-design-glyph-kmg-design" /></button></td>
              <td><button className='move-btn'
                onClick={() => changePosition("down", regulation.id)}
              ><img width="36" height="40" src="https://img.icons8.com/fluency-systems-filled/48/thick-arrow-pointing-down.png" alt="thick-arrow-pointing-down" /></button></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div >
  )
}
