import React, { useState, useEffect } from 'react';
import TabArchives from "react-bootstrap/Tab";
import TabsTabArchives from "react-bootstrap/Tabs";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { Grid, TextField } from '@mui/material';
import Form from 'react-bootstrap/Form';
import APIService from '../../../../APIService';
import swal from 'sweetalert';

export const Reports = () => {

  const reportTypes = [
    "Annual Report",
    "Administrative Report",
    "Financial Report",
    "Other Report",
  ];

  const [reports, setReports] = useState([]);
  const [report, setReport] = useState("");
  const [title, setTitle] = useState("");
  const [year, setYear] = useState("");
  const [fileUpload, setFileUpload] = useState("");

  const [reportsId, setReportsId] = useState("");
  const [isEditing, setIsEditing] = useState(false);



  useEffect(() => {
    APIService.get('reports').then((response) => {
      setReports(response.data);
    });
  }, []);

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFileUpload(e.target.files[0]);
    }
  }


  const changePosition = (direction, itemId) => {

    APIService.post([], `change-position/${direction}/reports/${itemId}`)
      .then((response) => {
        // console.log(response);
        if (response.success) {
          APIService.get('reports').then((response) => {
            setReports(response.data);
          });
        }
      })
  }


  const handleEdit = (itemId, report, title, year) => {
    setReportsId(itemId);
    setIsEditing(true);
    setReport(report)
    setTitle(title)
    setYear(year);
  };


  const handleDelete = (itemId) => {
    // e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "You want to delete this item?",
      icon: "warning",
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        APIService.delete(`reports/${itemId}`).then((response) => {
          swal("Success", response.message, "success", {
            buttons: false,
            timer: 2000,
          }).then((value) => {
            window.location.href = "/reports";
          });
        });
      }
    });
  };

  const handleSubmit = async (e) => {

    e.preventDefault();
    const formData = new FormData();

    formData.append("report", report);
    formData.append("title", title);
    formData.append("year", year);
    formData.append("file_upload", fileUpload);

    if (isEditing) {
      var response = await APIService.put(formData, `reports/${reportsId}`);
    } else {
      var response = await APIService.post(formData, "reports");
    }

    if (response.success) {
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).then((value) => {
        window.location.href = "/reports";
      });
    } else {
      swal("Failed", response.message, "error");
    }
  }

  return (
    <div>
      <h2 className="organisation-head">Reports</h2>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={4} className='order-select-option'>
            <p id="labels">Select Report</p>
            <Form.Select
              aria-label="Default select example"
              value={report}
              onChange={e => setReport(e.target.value)}
            >
              <option value="" disabled>Select</option>
              <option value="Annual Report">Annual Report</option>
              <option value="Administrative Report">Administrative Report</option>
              <option value="Financial Report">Financial Report</option>
              <option value="Other Report">Other Report</option>
            </Form.Select>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Year</p>
            <TextField
              type="number"
              name="year"
              label="Year"
              variant="outlined"
              fullWidth
              value={year}
              onChange={e => setYear(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={12} md={8}>
            <p id="labels">Title</p>
            <TextField
              fullWidth
              id="outlined-multiline-static"
              label="Title"
              multiline
              rows={3}
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Upload File</p>
            <TextField
              name="upload-photo"
              type="file"
              onChange={handleFileChange}
            />
          </Grid>
        </Grid>
        <Button style={{ marginTop: 20 }} variant="primary" type="submit" >Submit</Button>{' '}
      </form>

      <TabsTabArchives
        defaultActiveKey={reportTypes[0]}
        transition={false}
        id="noanim-tab-example"
        className="table-archives"
      >
        {reportTypes.map((type, index) => (
          <TabArchives eventKey={type} title={type} key={"tab_" + index}>
            <div className="archive-scroll">
              <Table striped bordered hover variant="light">
                <thead>
                  <tr>
                    <th className="table-no-aling">Sr.No.</th>
                    <th>Title</th>
                    <th>Year</th>
                    <th>Edit</th>
                    <th>Delete</th>
                    <th>Move Up</th>
                    <th>Move Down</th>
                  </tr>
                </thead>
                <tbody>
                  {reports
                    .filter(report => report.report === type)
                    .map((report, index) => (
                      <tr key={report.id}>
                        <td>{index + 1}</td>
                        <td>{report.title}</td>
                        <td>{report.year}</td>
                        <td><button className='tablebutton'
                          onClick={() => handleEdit(report.id, report.report, report.title, report.year)}
                        >Edit</button></td>
                        <td><button className='tablebutton'
                          onClick={() => handleDelete(report.id)}
                        >Delete</button></td>
                        <td>
                          <button
                            className='move-btn'
                            onClick={() => changePosition("up", report.id)}
                          >
                            <img width="32" height="32" src="https://img.icons8.com/external-kmg-design-glyph-kmg-design/32/external-up-arrow-arrows-kmg-design-glyph-kmg-design.png" alt="external-up-arrow-arrows-kmg-design-glyph-kmg-design" />
                          </button>
                        </td>
                        <td>
                          <button
                            className='move-btn'
                            onClick={() => changePosition("down", report.id)}
                          >
                            <img width="36" height="40" src="https://img.icons8.com/fluency-systems-filled/48/thick-arrow-pointing-down.png" alt="thick-arrow-pointing-down" />
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </TabArchives>
        ))}
      </TabsTabArchives>
    </div>
  )
}
