import React, { useState, useEffect } from 'react';
import { Grid, TextField } from '@mui/material';
import Button from 'react-bootstrap/Button';
import APIService from '../../../../APIService';
import swal from 'sweetalert';
import { Table } from 'react-bootstrap';

export default function CGRF() {
  const [cgrf, setCgrf] = useState([])
  const [caption, setCaption] = useState("");
  const [description, setDescription] = useState("");
  const [fileUpload, setFileUpload] = useState("");

  const [cgrfId, setCgrfId] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    APIService.get('cgrf').then((response) => {
      setCgrf(response.data);
    });
  }, []);

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFileUpload(e.target.files[0]);
    }
  }

  const handleEdit = (itemId, caption, description) => {
    setIsEditing(true);
    setCgrfId(itemId);
    setCaption(caption);
    setDescription(description)
  };


  const handleDelete = (itemId) => {
    // e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "You want to delete this item?",
      icon: "warning",
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        APIService.delete(`cgrf/${itemId}`).then((response) => {
          swal("Success", response.message, "success", {
            buttons: false,
            timer: 2000,
          }).then((value) => {
            window.location.href = "/cgrf";
          });
        });
      }
    });
  };

  const handleSubmit = async (e) => {

    e.preventDefault();
    const formData = new FormData();
    formData.append("caption", caption);
    formData.append("description", description);
    formData.append("image_upload", fileUpload);

    if (isEditing) {
      var response = await APIService.put(formData, `cgrf/${cgrfId}`);
    } else {
      var response = await APIService.post(formData, "cgrf");
    }

    if (response.success) {
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).then((value) => {
        window.location.href = "/cgrf";
      });
    } else {
      swal("Failed", response.message, "error");
    }
  }
  return (
    <div>
      <h2 className="organisation-head">CGRF</h2>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Caption</p>
            <TextField
              name="caption"
              label="Caption"
              variant="outlined"
              fullWidth
              value={caption}
              onChange={e => setCaption(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Upload Image</p>
            <TextField
              name="upload-photo"
              type="file"
              onChange={handleFileChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={12} md={8}>
            <p id="labels">Decription</p>
            <TextField
              fullWidth
              id="outlined-multiline-static"
              label="Decription"
              multiline
              rows={3}
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
          </Grid>
        </Grid>
        <Button style={{ marginTop: 20 }} variant="primary" type="submit">Submit</Button>{' '}
      </form>
      <Table striped bordered hover variant="light">
        <thead>
          <tr>
            <th>Sl No</th>
            <th>Caption</th>
            <th>Description</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {cgrf.map((cg, index) => (
            <tr key={cg.id}>
              <td>{index + 1}</td>
              <td>{cg.caption}</td>
              <td>{cg.description}</td>
              <td><button className='tablebutton'
                onClick={() => handleEdit(cg.id, cg.caption, cg.description)}
              >Edit</button></td>
              <td><button className='tablebutton'
                onClick={() => handleDelete(cg.id)}>Delete</button></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
