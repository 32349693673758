import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { Grid, TextField } from '@mui/material';
import Button from 'react-bootstrap/Button';
import TabArchives from "react-bootstrap/Tab";
import TabsTabArchives from "react-bootstrap/Tabs";
import Form from 'react-bootstrap/Form';
import APIService from '../../../../APIService';
import swal from 'sweetalert';

export const Rti = () => {

  const [rtiTypes, setRtiTypes] = useState([
    "SPIO",
    "Appellate Authority",
    "Mandatory Disclosure",
  ]);

  const [rtis, setRtis] = useState([]);
  const [rtiType, setRtiType] = useState("");
  const [name, setName] = useState("");
  const [designation, setDesignation] = useState("");
  const [authority, setAuthority] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [fileUpload, setFileUpload] = useState("");

  const [rtiId, setRtiId] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    APIService.get('rti').then((response) => {
      setRtis(response.data);
    });
  }, []);
  const handleFileChange = (e) => {
    if (e.target.files) {
      setFileUpload(e.target.files[0]);
    }
  }

  const handleEdit = (itemId, rtiType, name, designation, authority, address, phone, email, title) => {

    setRtiId(itemId);
    setRtiType(rtiType);
    setIsEditing(true);
    setName(name)
    setDesignation(designation)
    setAuthority(authority)
    setAddress(address)
    setPhone(phone)
    setEmail(email)
    setTitle(title);
  }

  const handleDelete = (itemId) => {

    swal({
      title: "Are you sure?",
      text: "You want to delete this item?",
      icon: "warning",
      dangerMode: true,
    })
      .then(willDelete => {
        if (willDelete) {
          APIService.delete(`rti/${itemId}`)
            .then(response => {
              swal("Success", response.message, "success", {
                buttons: false,
                timer: 2000,
              }).then((value) => {
                window.location.href = "/rti";
              });
            });
        }
      });
  }

  const handleSubmit = async (e) => {

    e.preventDefault();
    const formData = new FormData();

    formData.append("option", rtiType);
    formData.append("name", name);
    formData.append("designation", designation);
    formData.append("authority", authority);
    formData.append("address", address);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("title", title);
    formData.append("file_upload", fileUpload);

    if (isEditing) {

      var response = await APIService.put(formData, `rti/${rtiId}`);
    } else {

      var response = await APIService.post(formData, 'rti');
    }

    if (response.success) {
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).then((value) => {
        window.location.href = "/rti";
      });
    } else {
      swal("Failed", response.message, "error");
    }
  }

  return (
    <div>
      <h2 className="organisation-head">Right To Information</h2>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={4} className='order-select-option'>
            <p id="labels">Select Options</p>
            <Form.Select
              aria-label="Default select example"
              value={rtiType}
              onChange={e => setRtiType(e.target.value)}
            >
              <option value="" disabled>Select</option>
              <option value="SPIO">SPIO</option>
              <option value="Appellate Authority">Appellate Authority</option>
              <option value="Mandatory Disclosure">Mandatory Disclosure</option>
            </Form.Select>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Authority</p>
            <TextField
              name="authority"
              label="Authority"
              variant="outlined"
              fullWidth
              value={authority}
              onChange={e => setAuthority(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Name of Officer</p>
            <TextField
              name="namr_of_officer"
              label="Name of Officer"
              variant="outlined"
              fullWidth
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Designation</p>
            <TextField
              name="designation"
              label="Designation"
              variant="outlined"
              fullWidth
              value={designation}
              onChange={e => setDesignation(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Office Address</p>
            <TextField
              name="address"
              label="Office Address"
              variant="outlined"
              fullWidth
              value={address}
              onChange={e => setAddress(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Office Telephone</p>
            <TextField
              name="telephone"
              label="Office Telephone"
              variant="outlined"
              fullWidth
              value={phone}
              onChange={e => setPhone(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Email</p>
            <TextField
              name="email"
              label="email"
              variant="outlined"
              fullWidth
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Upload File</p>
            <TextField
              name="upload-photo"
              type="file"
              onChange={handleFileChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={12} md={8}>
            <p id="labels">Title</p>
            <TextField
              fullWidth
              id="outlined-multiline-static"
              label="Title"
              multiline
              rows={3}
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
          </Grid>
        </Grid>
        <Button style={{ marginTop: 20 }} variant="primary" type="submit" >Submit</Button>{' '}
      </form>
      <TabsTabArchives
        defaultActiveKey={rtiTypes[0]}
        transition={false}
        id="noanim-tab-example"
        className="table-archives"
      >
        {rtiTypes.map((type, index) => (
          <TabArchives eventKey={type} title={type} key={"tab_" + index}>
            <div className="archive-scroll">
              <Table striped bordered hover variant="light">
                <thead>
                  <tr>
                    <th>Authority</th>
                    <th>Name of Officer</th>
                    <th>Designation</th>
                    <th>Complete Office Address</th>
                    <th>Office Telephone No</th>
                    <th>Email Address</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {rtis
                    .filter(rt => rt.option === type)
                    .map((rt, index) => (
                      <tr key={rt.id}>
                        <td>{rt.authority}</td>
                        <td>{rt.name}</td>
                        <td>{rt.designation}</td>
                        <td>{rt.address}</td>
                        <td>{rt.phone}</td>
                        <td>{rt.email}</td>
                        <td><button className='tablebutton'
                          onClick={() => handleEdit(rt.id, rt.option, rt.name, rt.designation, rt.authority, rt.address, rt.phone, rt.email, rt.title)}
                        >Edit</button></td>
                        <td><button className='tablebutton'
                          onClick={() => handleDelete(rt.id)}
                        >Delete</button></td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </TabArchives>
        ))}
      </TabsTabArchives>
    </div>
  )
}
