import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { Grid } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import APIService from '../../../../APIService';
import swal from 'sweetalert';

export const ConsumerAdvocacy = () => {

  const [cacContent, setCacContent] = useState("");

  useEffect(() => {
    APIService.get('consumer_advocacy_cell')
      .then((response) => {
        setCacContent(response.data);
      });
  }, []);

  const handleSubmit = async (e) => {

    e.preventDefault();
    const formData = new FormData();

    formData.append("cac_content", cacContent);

    const response = await APIService.post(formData, 'consumer_advocacy_cell');

    if (response.success) {

      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).then((value) => {
        window.location.href = "/consumer_advocacy_cell";
      });
    } else {
      swal("Failed", response.message, "error");
    }
  }

  return (
    <div>
      <h1>Consumer Advocacy & Compliance Examination</h1>

      <form noValidate onSubmit={handleSubmit}>
        {/* <div className="quill"> */}
        <Grid item xs={12}>
          {/* Adding the react-quill rich text editor */}
          <ReactQuill
            style={{ height: 400 }}
            value={cacContent}
            onChange={setCacContent}
          />
        </Grid>
        {/* </div> */}
        <Button style={{ marginTop: 70 }} variant="primary" type="submit">Submit</Button>{' '}
      </form>

    </div>
  );
};
