import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import TabArchives from 'react-bootstrap/Tab';
import TabsTabArchives from 'react-bootstrap/Tabs';
import '../Orders/order.css'
import { Grid, TextField } from '@mui/material';
import Form from 'react-bootstrap/Form';
import APIService from '../../../../APIService';
import swal from 'sweetalert';

export default function Orders() {

  const orderTypes = [
    "Daily Orders",
    "Tariff Orders",
    "ARR Orders",
    "Truing Up Orders",
    "General Orders",
  ];

  const [orders, setOrders] = useState([]);
  const [order, setOrder] = useState("");
  const [orderType, setOrderType] = useState("");
  const [orderNo, setOrderNo] = useState("");
  const [orderDate, setOrderDate] = useState("");
  const [fileUpload, setFileUpload] = useState("");

  const [ordersId, setordersId] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const [licensees, setLicensees] = useState([]);
  const [selectedLicensee, setSelectedLicensee] = useState('');

  useEffect(() => {
    APIService.get('licensees').then((response) => {
      setLicensees(response.data);
    })
  }, [])


  useEffect(() => {
    APIService.get('orders').then((response) => {
      setOrders(response.data);
    });
  }, []);

  const handleFileChange = (e) => {

    if (e.target.files) {
      setFileUpload(e.target.files[0]);
    }
  }

  const changePosition = (direction, itemId) => {

    APIService.post([], `change-position/${direction}/orders/${itemId}`)
      .then((response) => {
        // console.log(response);
        if (response.success) {
          APIService.get('orders').then((response) => {
            setOrders(response.data);
          });
        }
      })
  }


  const handleEdit = (itemId, orderType, order, orderNo, orderDate, licensee_id,) => {
    setordersId(itemId);
    setIsEditing(true);
    setOrderType(orderType);
    setOrder(order);
    setOrderNo(orderNo);
    setOrderDate(orderDate)
    setSelectedLicensee(licensee_id);
  };

  const handleDelete = (itemId) => {
    // e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "You want to delete this item?",
      icon: "warning",
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        APIService.delete(`orders/${itemId}`).then((response) => {
          swal("Success", response.message, "success", {
            buttons: false,
            timer: 2000,
          }).then((value) => {
            window.location.href = "/orders";
          });
        });
      }
    });
  };

  const handleSubmit = async (e) => {

    e.preventDefault();
    const formData = new FormData();

    formData.append("order", order);
    formData.append("option", orderType);
    formData.append("order_no", orderNo);
    formData.append("order_date", orderDate);
    formData.append("file_upload", fileUpload);
    formData.append("licensee_id", selectedLicensee)

    if (isEditing) {
      var response = await APIService.put(formData, `orders/${ordersId}`);
    } else {
      var response = await APIService.post(formData, "orders");
    }


    if (response.success) {

      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).then((value) => {

        window.location.href = "/orders";
      });
    } else {

      swal("Failed", response.message, "error");
    }
  }

  return (
    <div>
      <h2 className="organisation-head">Orders</h2>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={6} className='order-select-option'>
            <p id="labels">Select Options</p>
            <Form.Select
              aria-label="Default select example"
              value={orderType}
              onChange={e => setOrderType(e.target.value)}
            >
              <option value="" disabled>Select</option>
              <option value="Daily Orders">Daily Orders</option>
              <option value="Tariff Orders">Tariff Orders</option>
              <option value="ARR Orders">ARR Orders</option>
              <option value="Truing Up Orders">Truing Up Orders</option>
              <option value="General Orders">General Orders</option>
            </Form.Select>
          </Grid>

          <Grid item xs={12} sm={6} lg={6} className="order-select-option">
            <p id="labels">Select Licensee</p>
            <Form.Select
              aria-label="Default select example"
              value={selectedLicensee}
              onChange={e => setSelectedLicensee(e.target.value)}
            >

              <option value="" disabled>Select an Option</option>
              {licensees.map((licensee, index) => (
                <option value={licensee.id} key={index}>
                  {licensee.heading}
                </option>
              ))}
            </Form.Select>
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Order No</p>
            <TextField
              name="order_no"
              label="Order No"
              variant="outlined"
              fullWidth
              value={orderNo}
              onChange={e => setOrderNo(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={12} md={8}>
            <p id="labels">Order</p>
            <TextField
              fullWidth
              id="outlined-multiline-static"
              label="Order"
              multiline
              rows={3}
              value={order}
              onChange={e => setOrder(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Date</p>
            <TextField
              type="date"
              name="date"
              value={orderDate}
              variant="outlined"
              fullWidth
              onChange={e => setOrderDate(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Upload File</p>
            <TextField
              name="upload-photo"
              type="file"
              onChange={handleFileChange}
            />
          </Grid>
        </Grid>
        <Button style={{ marginTop: 20 }} variant="primary" type="submit" >Submit</Button>{' '}
      </form>
      <TabsTabArchives
        defaultActiveKey={orderTypes[0]}
        transition={false}
        id="noanim-tab-example"
        className="table-archives"
      >
        {orderTypes.map((type, index) => (
          <TabArchives eventKey={type} title={type} key={"tab_" + index}>
            <div >
              <Table striped bordered hover variant="light">
                <thead>
                  <tr>
                    <th className='table-no-aling'>Sr.No. </th>
                    <th>Order No.</th>
                    <th>Order</th>
                    <th>Date</th>
                    <th>Edit</th>
                    <th>Delete</th>
                    <th>Move Up</th>
                    <th>Move Down</th>
                  </tr>
                </thead>
                <tbody>
                  {orders
                    .filter(order => order.option === type)
                    .map((order, index) => (
                      <tr key={order.id}>
                        <td className='table-no-aling' >{index + 1}</td>
                        <td>{order.order_no}</td>
                        <td>{order.order}</td>
                        <td>{order.order_date}</td>
                        <td>
                          <button
                            className='tablebutton'
                            onClick={() => handleEdit(order.id, order.option, order.order, order.order_no, order.order_date, order.licensee_id)}
                          >
                            Edit
                          </button>
                        </td>
                        <td>
                          <button
                            className='tablebutton'
                            onClick={() => handleDelete(order.id)}
                          >
                            Delete
                          </button>
                        </td>
                        <td>
                          <button
                            className='move-btn'
                            onClick={() => changePosition("up", order.id)}
                          >
                            <img width="32" height="32" src="https://img.icons8.com/external-kmg-design-glyph-kmg-design/32/external-up-arrow-arrows-kmg-design-glyph-kmg-design.png" alt="external-up-arrow-arrows-kmg-design-glyph-kmg-design" />
                          </button>
                        </td>
                        <td>
                          <button
                            className='move-btn'
                            onClick={() => changePosition("down", order.id)}
                          >
                            <img width="36" height="40" src="https://img.icons8.com/fluency-systems-filled/48/thick-arrow-pointing-down.png" alt="thick-arrow-pointing-down" />
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </TabArchives>
        ))}
      </TabsTabArchives>
    </div>
  )
}
