import React, { useState, useEffect } from 'react';
import { Grid, TextField } from '@mui/material';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import APIService from '../../../../APIService';
import swal from 'sweetalert';

export const History = () => {
  const [histories, setHistories] = useState([]);
  const [heading, setHeading] = useState("");
  const [name, setName] = useState("");
  const [tenure, setTenure] = useState("");
  const [fileUpload, setFileUpload] = useState("");

  const [historyId, setHistoryId] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    APIService.get('history').then((response) => {
      setHistories(response.data);
    })
  }, [])
  const handleFileChange = (e) => {
    if (e.target.files) {
      setFileUpload(e.target.files[0]);
    }
  };

  const handleEdit = (itemId, heading, name, tenure) => {
    setHistoryId(itemId);
    setIsEditing(true);
    setHeading(heading);
    setName(name);
    setTenure(tenure)
  };


  const handleDelete = (itemId) => {
    // e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "You want to delete this item?",
      icon: "warning",
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        APIService.delete(`history/${itemId}`).then((response) => {
          swal("Success", response.message, "success", {
            buttons: false,
            timer: 2000,
          }).then((value) => {
            window.location.href = "/history";
          });
        });
      }
    });
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("heading", heading);
    formData.append("tenure", tenure);
    formData.append("file_upload", fileUpload);

    if (isEditing) {
      var response = await APIService.put(formData, `history/${historyId}`);
    } else {
      var response = await APIService.post(formData, "history");
    }


    if (response.success) {
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).then((value) => {
        window.location.href = "/history";
      });
    } else {
      swal("Failed", response.message, "error");
    }
  }
  return (
    <div>
      <h2 className="organisation-head">History</h2>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          {/* <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Heading</p>
            <TextField
              name="heading"
              label="Heading"
              variant="outlined"
              fullWidth
              value={heading}
              onChange={e => setHeading(e.target.value)}
            />
          </Grid> */}
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Name</p>
            <TextField
              name="name"
              label="Name"
              variant="outlined"
              fullWidth
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Tenure</p>
            <TextField
              name="tenure"
              label="Tenure"
              variant="outlined"
              fullWidth
              value={tenure}
              onChange={e => setTenure(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Upload Image</p>
            <TextField
              name="upload-photo"
              type="file"
              onChange={handleFileChange}
            />
          </Grid>
        </Grid>
        <Button style={{ marginTop: 20 }} variant="primary" type='submit'>Submit</Button>{' '}
      </form>
      <Table responsive="sm mt-5">
        <thead>
          <tr>
            <th>Sr.No</th>
            {/* <th>Heading</th> */}
            <th>Image </th>
            <th>Name</th>
            <th>Tenure </th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {histories.map((history, index) => (
            <tr key={history.id}>
              <td>{index + 1}</td>
              {/* <td>{history.heading}</td> */}
              <td></td>
              <td>{history.name}</td>
              <td>{history.tenure}</td>
              <td><button className='tablebutton'
                onClick={() => handleEdit(history.id, history.heading, history.name, history.tenure)}
              >Edit</button></td>
              <td><button className='tablebutton'
                onClick={() => handleDelete(history.id)}
              >Delete</button></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
