// import React, { useState, useEffect } from "react";
// import Table from "react-bootstrap/Table";
// import Button from "react-bootstrap/Button";
// import { Grid, TextField } from "@mui/material";
// import APIService from "../../../../APIService";
// import swal from "sweetalert";

// export const Announcements = () => {
//   const [announcement, setAnnouncement] = useState([]);
//   const [title, setTitle] = useState("");
//   const [url, setUrl] = useState("");

//   const [announcementId, setAnnouncementId] = useState("");
//   const [isEditing, setIsEditing] = useState(false);

//   useEffect(() => {
//     APIService.get("announcements").then((response) => {
//       setAnnouncement(response.data);
//     });
//   }, []);

//   const handleEdit = (itemId, title, url) => {
//     setAnnouncementId(itemId);
//     setIsEditing(true);
//     setTitle(title);
//     setUrl(url);
//   };

//   const handleDelete = (itemId) => {
//     swal({
//       title: "Are you sure?",
//       text: "You want to delete this item?",
//       icon: "warning",
//       dangerMode: true,
//     }).then((willDelete) => {
//       if (willDelete) {
//         APIService.delete(`announcements/destroy/${itemId}`).then((response) => {
//           swal("Success", response.message, "success", {
//             buttons: false,
//             timer: 2000,
//           }).then(() => {
//             window.location.href = "/announcement";
//           });
//         });
//       }
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const formData = new FormData();
//     formData.append("title", title);
//     formData.append("url", url);

//     let response;
//     try {
//       if (isEditing) {
//         response = await APIService.put(formData, `announcements/update/${announcementId}`);
//       } else {
//         response = await APIService.post(formData, "announcements/store");
//       }

//       if (response.success) {
//         swal("Success", response.message, "success", {
//           buttons: false,
//           timer: 2000,
//         }).then(() => {
//           window.location.href = "/announcement";
//         });
//       } else {
//         swal("Failed", response.message, "error");
//       }
//     } catch (error) {
//       swal("Failed", error.message, "error");
//     }
//   };

//   return (
//     <div>
//       <h2 className="organisation-head">Announcements</h2>
//       <form noValidate onSubmit={handleSubmit}>
//         <Grid container spacing={3} style={{ marginTop: 10 }}>
//           <Grid item xs={12} sm={6} lg={4}>
//             <p id="labels">URL</p>
//             <TextField
//               fullWidth
//               name="url"
//               label="URL"
//               variant="outlined"
//               value={url}
//               onChange={(e) => setUrl(e.target.value)}
//             />
//           </Grid>
//         </Grid>
//         <Grid container spacing={3} style={{ marginTop: 10 }}>
//           <Grid item xs={12} sm={6} lg={12} md={8}>
//             <p id="labels">Title</p>
//             <TextField
//               fullWidth
//               id="outlined-multiline-static"
//               label="Title"
//               multiline
//               rows={3}
//               value={title}
//               onChange={(e) => setTitle(e.target.value)}
//             />
//           </Grid>
//         </Grid>
//         <Button style={{ marginTop: 20 }} variant="primary" type="submit">
//           Submit
//         </Button>{" "}
//       </form>
//       <Table striped bordered hover variant="light">
//         <thead>
//           <tr>
//             <th>Sl No</th>
//             <th>Title</th>
//             <th>URL</th>
//             <th>Edit</th>
//             <th>Delete</th>
//           </tr>
//         </thead>
//         <tbody>
//           {announcement && announcement.map((announcement, index) => (
//             <tr key={announcement.id}>
//               <td>{index + 1}</td>
//               <td>{announcement.title}</td>
//               <td>{announcement.url}</td>
//               <td>
//                 <button
//                   className="tablebutton"
//                   onClick={() => handleEdit(announcement.id, announcement.title, announcement.url)}
//                 >
//                   Edit
//                 </button>
//               </td>
//               <td>
//                 <button className="tablebutton" onClick={() => handleDelete(announcement.id)}>Delete</button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>
//     </div>
//   );
// };
import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { Grid, TextField } from "@mui/material";
import APIService from "../../../../APIService";
import swal from "sweetalert";

export const Announcements = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [announcementId, setAnnouncementId] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    fetchAnnouncements();
  }, []);

  const fetchAnnouncements = async () => {
    try {
      const response = await APIService.get("announcements");
      setAnnouncements(response.data);
    } catch (error) {
      console.error("Error fetching announcements:", error);
    }
  };

  const handleEdit = (itemId, title, url) => {
    setAnnouncementId(itemId);
    setIsEditing(true);
    setTitle(title);
    setUrl(url);
  };

 
  // const handleDelete = (itemId) => {
  //   console.log("Deleting announcement with ID:", itemId);
  //   swal({
  //     title: "Are you sure?",
  //     text: "You want to delete this item?",
  //     icon: "warning",
  //     dangerMode: true,
  //   }).then((willDelete) => {
  //     if (willDelete) {
  //       APIService.delete(`announcements/${itemId}`)
  //         .then((response) => {
  //           console.log("Delete response:", response);
  //           if (response.data.success) {
  //             console.log("Announcement deleted successfully");
  //             swal("Success", "Announcement deleted successfully", "success").then(() => {
  //               fetchAnnouncements();
  //             });
  //           } else {
  //             console.error("Failed to delete announcement:", response.data.message);
  //             swal("Failed", "Failed to delete announcement", "error");
  //           }
  //         })
  //         .catch((error) => {
  //           console.error("Error deleting announcement:", error);
  //           swal("Failed", "An error occurred while deleting the announcement", "error");
  //         });
  //     }
  //   });
  // };
  const handleDelete = (itemId) => {
    console.log("Deleting announcement with ID:", itemId);
    swal({
      title: "Are you sure?",
      text: "You want to delete this item?",
      icon: "warning",
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        APIService.delete(`announcements/${itemId}`)
          .then((response) => {
            swal("Success", response.message, "success", {
              buttons: false,
              timer: 2000,
            }).then((value) => {
              window.location.href = "/announcement";
            });
          });
        }
      });
    };
  
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", title);
    formData.append("url", url);

    try {
      let response;
      if (isEditing) {
        response = await APIService.put(formData, `announcements/${announcementId}`);
      } else {
        response = await APIService.post(formData, "announcements");
      }

      console.log("Update Announcement Response:", response);

      if (response.success) {
        swal("Success", response.message, "success").then(() => {
          fetchAnnouncements();
          setTitle("");
          setUrl("");
          setIsEditing(false);
        });
      } else {
        swal("Failed", response.message, "error");
      }
    } catch (error) {
      console.error("Error submitting announcement:", error);
      swal("Failed", "An error occurred while submitting the announcement", "error");
    }
  };

  return (
    <div>
      <h2 className="organisation-head">Announcements</h2>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">URL</p>
            <TextField
              fullWidth
              name="url"
              label="URL"
              variant="outlined"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={12} md={8}>
            <p id="labels">Title</p>
            <TextField
              fullWidth
              id="outlined-multiline-static"
              label="Title"
              multiline
              rows={3}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Grid>
        </Grid>
        <Button style={{ marginTop: 20 }} variant="primary" type="submit">
          Submit
        </Button>{" "}
      </form>
      <Table striped bordered hover variant="light">
        <thead>
          <tr>
            <th>Sl No</th>
            <th>Title</th>
            <th>URL</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {announcements.map((announcement, index) => (
            <tr key={announcement.id}>
              <td>{index + 1}</td>
              <td>{announcement.title}</td>
              <td>{announcement.url}</td>
              <td>
                <button
                  className="tablebutton"
                  onClick={() => handleEdit(announcement.id, announcement.title, announcement.url)}
                >
                  Edit
                </button>
              </td>
              <td>
                <button className="tablebutton" onClick={() => handleDelete(announcement.id)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};