import React, { useState, useEffect } from 'react';
import { Grid, TextField } from '@mui/material';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import APIService from '../../../../APIService';
import swal from 'sweetalert';

export const StartupThumbnail = () => {

  const [thumbnails, setThumbnails] = useState([]);
  const [heading, setHeading] = useState("");
  const [fileUpload, setFileUpload] = useState("");

  const [startupThumbId, setStartupThumbId] = useState("");
  const [isEditing, setIsEditing] = useState(false);


  useEffect(() => {
    APIService.get('startup_thumbnail').then((response) => {
      setThumbnails(response.data);
    })
  }, []);

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFileUpload(e.target.files[0]);
    }
  }

  const handleEdit = (itemId, heading) => {
    setStartupThumbId(itemId);
    setIsEditing(true);
    setHeading(heading);
  }

  const handleDelete = (itemId) => {

    // e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "You want to delete this item?",
      icon: "warning",
      dangerMode: true,
    })
      .then(willDelete => {
        if (willDelete) {
          APIService.delete(`startup_thumbnail/${itemId}`)
            .then(response => {
              swal("Success", response.message, "success", {
                buttons: false,
                timer: 2000,
              }).then((value) => {
                window.location.href = "/startup";
              });
            });
        }
      });
  }

  const handleSubmit = async (e) => {

    e.preventDefault();
    const formData = new FormData();

    formData.append("heading", heading);
    formData.append("thumbnail_image", fileUpload);

    if (isEditing) {

      var response = await APIService.put(formData, `startup_thumbnail/${startupThumbId}`);
    } else {

      var response = await APIService.post(formData, 'startup_thumbnail');
    }

    if (response.success) {

      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).then((value) => {

        window.location.href = "/startup";
      });
    } else {

      swal("Failed", response.message, "error");
    }
  }

  return (
    <div>
      <h2 className="organisation-head">Startup Entrepreneurship</h2>

      <form noValidate onSubmit={handleSubmit}>
        <h3>Thumbnail Upload</h3>
        <Grid container spacing={3} className='mt-2'>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Heading</p>
            <TextField
              name="Heading"
              label="Heading"
              variant="outlined"
              fullWidth
              value={heading}
              onChange={e => setHeading(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Upload Thumbnail</p>
            <TextField
              name="upload-photo"
              type="file"
              onChange={handleFileChange}
            />
          </Grid>
        </Grid>
        <Button style={{ marginTop: 20 }} variant="primary" type='submit'>Upload</Button>{' '}
      </form>

      <Table responsive="sm mt-5">
        <thead>
          <h3>Thumbnail List</h3>
          <tr>
            <th>Sr.No</th>
            <th>Thumbnail Heading</th>
            <th>Thumbnail Image</th>
            <th>Sartup Photo</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {thumbnails.map((thumbnail, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{thumbnail.heading}</td>
              <td></td>
              <td><a href={"/startup/" + thumbnail.id} >Startup Photo</a></td>
              <td><button className='tablebutton'
                onClick={() => handleEdit(thumbnail.id, thumbnail.heading)}
              >Edit</button></td>
              <td><button className='tablebutton'
                onClick={() => handleDelete(thumbnail.id)}
              >Delete</button></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
