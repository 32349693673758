import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { Col, Container, Row } from 'react-bootstrap'
import { Grid, TextField } from '@mui/material';
import APIService from '../../../../APIService';
import swal from 'sweetalert';

export const DashboardKserc = () => {

  const [links, setLinks] = useState([])
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");

  const [dashboardId, setDashboardId] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    APIService.get('dashboard_link')
      .then((response) => {
        setLinks(response.data);
      });
  }, []);

  const changePosition = (direction, itemId) => {

    APIService.post([], `change-position/${direction}/dashboard_link/${itemId}`)
      .then((response) => {
        // console.log(response);
        if (response.success) {
          APIService.get('dashboard_link').then((response) => {
            setLinks(response.data);
          });
        }
      })
  }

  const handleEdit = (itemId, title, link) => {

    setDashboardId(itemId);
    setIsEditing(true);
    setTitle(title);
    setLink(link);

  }

  const handleDelete = (itemId) => {

    // e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "You want to delete this item?",
      icon: "warning",
      dangerMode: true,
    })
      .then(willDelete => {
        if (willDelete) {
          APIService.delete(`dashboard_link/${itemId}`)
            .then(response => {
              swal("Success", response.message, "success", {
                buttons: false,
                timer: 2000,
              }).then((value) => {
                window.location.href = "/dashboard-kserc";
              });
            });
        }
      });
  }



  const [petitionsDate, setPetitionsDate] = useState("");
  const [petitionsCount, setPetitionsCount] = useState("");
  const [ordersDate, setOrdersDate] = useState("");
  const [ordersCount, setOrdersCount] = useState("");

  useEffect(() => {
    APIService.get('dashboard_count')
      .then((response) => {
        setPetitionsDate(response.petitions_as_on_date);
        setPetitionsCount(response.petitions_count);
        setOrdersDate(response.orders_as_on_date);
        setOrdersCount(response.orders_count);
      });
  }, []);

  const handleSubmit = async (e) => {

    e.preventDefault();
    const formData = new FormData();

    formData.append("petitions_as_on_date", petitionsDate);
    formData.append("petitions_count", petitionsCount);
    formData.append("orders_as_on_date", ordersDate);
    formData.append("orders_count", ordersCount);

    const response = await APIService.post(formData, 'dashboard_count');

    if (response.success) {
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).then((value) => {
        window.location.href = "/dashboard-kserc";
      });
    } else {
      swal("Failed", response.message, "error");
    }
  }

  const handleLinkSubmit = async (e) => {

    e.preventDefault();
    const formData = new FormData();

    formData.append("title", title);
    formData.append("link", link);

    if (isEditing) {

      var response = await APIService.put(formData, `dashboard_link/${dashboardId}`);
    } else {

      var response = await APIService.post(formData, 'dashboard_link');
    }

    if (response.success) {
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).then((value) => {
        window.location.href = "/dashboard-kserc";
      });
    } else {
      swal("Failed", response.message, "error");
    }
  }

  return (
    <div>
      <Container fluid className='right-to-information' >
        <Row>
          <Col md={12}>
            <div className="organisation-img">
              <h2 className='organisation-head'>Dashboard</h2>
            </div>
          </Col>
        </Row>
      </Container>

      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={12} lg={12}>
            <h4 style={{ marginBottom: 10 }}>Petitions as on</h4>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} >
            <p id="labels">Date </p>
            <TextField
              type="date"
              variant="outlined"
              fullWidth
              value={petitionsDate}
              onChange={e => setPetitionsDate(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} >
            <p id="labels">Number</p>
            <TextField
              type='number'
              variant="outlined"
              fullWidth
              value={petitionsCount}
              onChange={e => setPetitionsCount(e.target.value)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={12} lg={12}>
            <h4 style={{ marginBottom: 10 }}>Orders Issued as on</h4>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} >
            <p id="labels">Date </p>
            <TextField
              type="date"
              variant="outlined"
              fullWidth
              value={ordersDate}
              onChange={e => setOrdersDate(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} >
            <p id="labels">Number</p>
            <TextField
              type='number'
              variant="outlined"
              fullWidth
              value={ordersCount}
              onChange={e => setOrdersCount(e.target.value)}
            />
          </Grid>
        </Grid>
        <Button style={{ marginTop: 20 }} variant="primary" type="submit">Update</Button>{' '}
      </form>

      <Grid item xs={12} sm={12} lg={12} style={{ marginTop: 30 }}>
        <h2 style={{ marginBottom: 10 }}>Dashboard Links</h2>
      </Grid>

      <form noValidate onSubmit={handleLinkSubmit}>
        <Grid container spacing={3} style={{ marginTop: 30 }}>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Title</p>
            <TextField
              name="title"
              label="Title"
              variant="outlined"
              fullWidth
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Link</p>
            <TextField
              name="link"
              label="Link"
              variant="outlined"
              fullWidth
              value={link}
              onChange={e => setLink(e.target.value)}
            />
          </Grid>
        </Grid>
        <Button style={{ marginTop: 20 }} variant="primary" type="submit">Submit</Button>{' '}
      </form>

      <Table striped bordered hover variant="light">
        <thead>
          <tr>
            <th className='table-no-aling'>Sr.No. </th>
            <th>Title</th>
            <th>Link</th>
            <th>Edit</th>
            <th>Delete</th>
            <th>Move Up</th>
            <th>Move Down</th>
          </tr>
        </thead>
        <tbody>
          {links.map((link, index) => (
            <tr key={index}>
              <td className='table-no-aling' >{index + 1}</td>
              <td>{link.title}</td>
              <td>{link.link}</td>
              <td><button className='tablebutton'
                onClick={() => handleEdit(link.id, link.title, link.link)}
              >Edit</button></td>
              <td><button className='tablebutton'
                onClick={() => handleDelete(link.id)}
               >Delete</button></td>
              <td><button className='move-btn'
                onClick={() => changePosition("up", link.id)}
              ><img width="32" height="32" src="https://img.icons8.com/external-kmg-design-glyph-kmg-design/32/external-up-arrow-arrows-kmg-design-glyph-kmg-design.png" alt="external-up-arrow-arrows-kmg-design-glyph-kmg-design" /></button></td>
              <td><button className='move-btn'
                onClick={() => changePosition("down", link.id)}
              ><img width="36" height="40" src="https://img.icons8.com/fluency-systems-filled/48/thick-arrow-pointing-down.png" alt="thick-arrow-pointing-down" /></button></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
