import React, { useState, useEffect } from 'react';
import { Grid, TextField } from '@mui/material';
import ReactQuill from 'react-quill'; // Import the react-quill component
import 'react-quill/dist/quill.snow.css'; // Import the react-quill CSS for styling
import Button from 'react-bootstrap/Button';
import APIService from '../../../../APIService';
import swal from 'sweetalert';
import { Table } from 'react-bootstrap';

export const Faq = () => {
  const [faqs, setFaqs] = useState([])
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  const [faqId, setFaqId] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    APIService.get('faq').then((response) => {
      setFaqs(response.data);
    });
  }, []);

  const handleEdit = (itemId, question, answer) => {
    setFaqId(itemId);
    setIsEditing(true);
    setQuestion(question);
    setAnswer(answer)
    // setDescription(description)
  }

  const handleDelete = (itemId) => {

    // e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "You want to delete this item?",
      icon: "warning",
      dangerMode: true,
    })
      .then(willDelete => {
        if (willDelete) {
          APIService.delete(`faq/${itemId}`)
            .then(response => {
              swal("Success", response.message, "success", {
                buttons: false,
                timer: 2000,
              }).then((value) => {
                window.location.href = "/faq";
              });
            });
        }
      });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("question", question);
    formData.append("answer", answer);

    if (isEditing) {

      var response = await APIService.put(formData, `faq/${faqId}`);
    } else {

      var response = await APIService.post(formData, 'faq');
    }

    if (response.success) {
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).then((value) => {
        window.location.href = "/faq";
      });
    } else {
      swal("Failed", response.message, "error");
    }
  }
  return (
    <div>
      <h2 className="organisation-head">Faq</h2>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={12} md={8}>
            <p id="labels">Question</p>
            <TextField
              fullWidth
              id="outlined-multiline-static"
              label="Question"
              multiline
              rows={3}
              value={question}
              onChange={e => setQuestion(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={12} md={8}>
            <p id="labels">Answer</p>
            <ReactQuill
              style={{ height: 200 }}
              onChange={setAnswer}
              value={answer}
            />
          </Grid>
        </Grid>
        <Button style={{ marginTop: 60 }} variant="primary" type='submit'>Submit</Button>{' '}
      </form>
      <Table striped bordered hover variant="light">
        <thead>
          <tr>
            <th>Sl No</th>
            <th>Question</th>
            <th>Answer</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {faqs.map((faq, index) => (
            <tr key={faq.id}>
              <td>{index + 1}</td>
              <td>{faq.question}</td>
              <td>
                <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
              </td>
              <td><button className='tablebutton'
                onClick={() => handleEdit(faq.id, faq.question, faq.answer)}
              >Edit</button></td>
              <td><button className='tablebutton'
                onClick={() => handleDelete(faq.id)}
              >Delete</button></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div >
  )
}
