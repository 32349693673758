import React, { useState, useEffect } from "react";
import { Grid, TextField } from "@mui/material";
import Button from "react-bootstrap/Button";
import ReactQuill from "react-quill"; // Import the react-quill component
import "react-quill/dist/quill.snow.css"; // Import the react-quill CSS for styling
import APIService from "../../../../APIService";
import swal from "sweetalert";
import { Table } from "react-bootstrap";

export const News = () => {
  const [news, setNews] = useState([]);
  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");
  const [fileUpload, setFileUpload] = useState("");

  const [newsId, setNewsId] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    APIService.get("news").then((response) => {
      setNews(response.data);
    });
  }, []);
  const handleFileChange = (e) => {
    if (e.target.files) {
      setFileUpload(e.target.files[0]);
    }
  };

  const handleEdit = (itemId, heading, description) => {
    setNewsId(itemId);
    setIsEditing(true);
    setHeading(heading);
    setDescription(description);
  };

  const handleDelete = (itemId) => {
    // e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "You want to delete this item?",
      icon: "warning",
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        APIService.delete(`news/${itemId}`).then((response) => {
          swal("Success", response.message, "success", {
            buttons: false,
            timer: 2000,
          }).then((value) => {
            window.location.href = "/news";
          });
        });
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("heading", heading);
    formData.append("description", description);
    formData.append("image_upload", fileUpload);

    if (isEditing) {
      var response = await APIService.put(formData, `news/${newsId}`);
    } else {
      var response = await APIService.post(formData, "news");
    }

    if (response.success) {
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).then((value) => {
        window.location.href = "/news";
      });
    } else {
      swal("Failed", response.message, "error");
    }
  };
  return (
    <div>
      <h2 className="organisation-head">News</h2>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Heading</p>
            <TextField
              name="heading"
              label="Heading"
              variant="outlined"
              fullWidth
              value={heading}
              onChange={(e) => setHeading(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Upload Image</p>
            <TextField
              name="upload-photo"
              type="file"
              onChange={handleFileChange}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} lg={12} md={8} className="mt-5">
          <p id="labels">Decription</p>
          <ReactQuill
            style={{ height: 200 }}
            onChange={setDescription}
            value={description}
          />
        </Grid>
        <Button style={{ marginTop: 60 }} variant="primary" type="submit">
          Submit
        </Button>{" "}
      </form>
      <Table striped bordered hover variant="light">
        <thead>
          <tr>
            <th>Sl No</th>
            <th>Heading</th>
            <th>Description</th>
            <th>Image</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {news.map((n, index) => (
            <tr key={n.id}>
              <td>{index + 1}</td>
              <td>{n.heading}</td>
              <td>
                <div dangerouslySetInnerHTML={{ __html: n.description }} />
              </td>
              <td></td>
              <td>
                <button
                  className="tablebutton"
                  onClick={() => handleEdit(n.id, n.heading, n.description)}
                >
                  Edit
                </button>
              </td>
              <td>
                <button
                  className="tablebutton"
                  onClick={() => handleDelete(n.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};
