import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { TextField } from '@mui/material';
import { Grid } from '@mui/material';
import APIService from '../../../../APIService';

export default function StateAdvisoryCommettee() {

  const [stateadvisory, setStateadvisory] = useState([]);
  const [name, setName] = useState("");
  const [designation, setDesignation] = useState("");
  const [address, setAddress] = useState("");

  const [stateAdvisoryId, setStateAdvisoryId] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    APIService.get('stateadvisory').then((response) => {
      setStateadvisory(response.stateadvisory);
    })
  }, []);

  const changePosition = (direction, itemId) => {

    APIService.post([], `change-position/${direction}/stateadvisory/${itemId}`)
      .then((response) => {
        // console.log(response);
        if (response.success) {
          APIService.get('stateadvisory').then((response) => {
            setStateadvisory(response.stateadvisory);
          });
        }
      })
  }

  const handleEdit = (itemId, name, designation, address) => {

    setStateAdvisoryId(itemId);
    setIsEditing(true);
    setName(name);
    setDesignation(designation);
    setAddress(address);
  }

  const handleDelete = (itemId) => {

    // e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "You want to delete this item?",
      icon: "warning",
      dangerMode: true,
    })
      .then(willDelete => {
        if (willDelete) {
          APIService.delete(`stateadvisory/${itemId}`)
            .then(response => {
              swal("Success", response.message, "success", {
                buttons: false,
                timer: 2000,
              }).then((value) => {
                window.location.href = "/stateadvisory";
              });
            });
        }
      });
  }

  const handleSubmit = async (e) => {

    e.preventDefault();

    const formData = new FormData();

    formData.append("name", name);
    formData.append("address", address);
    formData.append("designation", designation);

    if (isEditing) {
      var response = await APIService.put(formData, `stateadvisory/${stateAdvisoryId}`);
    } else {
      var response = await APIService.post(formData, 'stateadvisory');
    }

    if (response.success) {

      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).then((value) => {

        window.location.href = "/stateadvisory";
      });
    } else {

      swal("Failed", response.message, "error");
    }
  }
  return (
    <div>
      <h2 className="organisation-head">State Advisory
        Committee</h2>
      <h3 className='heading'>SAC</h3>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={6}>
            <p id="labels">Name</p>
            <TextField
              name="name"
              label="Name"
              variant="outlined"
              fullWidth
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <p id="labels">Designation</p>
            <TextField
              name="designation"
              label="Designation"
              variant="outlined"
              fullWidth
              value={designation}
              onChange={e => setDesignation(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={12}>
            <p id="labels">Address</p>
            <TextField
              name="address"
              label="Address"
              variant="outlined"
              fullWidth
              value={address}
              onChange={e => setAddress(e.target.value)}
            />
          </Grid>
        </Grid>
        <Button style={{ marginTop: 20 }} variant="primary" type="submit">Submit</Button>{' '}
      </form>

      <Table striped bordered hover variant="light">
        <thead>
          <tr>
            <th>Sl No</th>
            <th>Name/Title</th>
            <th>Address</th>
            <th>Designation</th>
            <th>Edit</th>
            <th>Delete</th>
            <th>Move Up</th>
            <th>Move Down</th>
          </tr>
        </thead>
        <tbody>
          {stateadvisory.map((advisory, index) => (
            <tr key={advisory.id}>
              <td>{index + 1}</td>
              <td>{advisory.name}</td>
              <td>{advisory.address}</td>
              <td>{advisory.designation}</td>
              <td>
                <button
                  className='tablebutton'
                  onClick={() => handleEdit(advisory.id, advisory.name, advisory.designation , advisory.address)}
                >
                  Edit
                </button>
              </td>
              <td>
                <button
                  className='tablebutton'
                  onClick={() => handleDelete(advisory.id)}
                >
                  Delete
                </button>
              </td>
              <td>
                <button
                  className='move-btn'
                  onClick={() => changePosition("up", advisory.id)}
                >
                  <img width="32" height="32" src="https://img.icons8.com/external-kmg-design-glyph-kmg-design/32/external-up-arrow-arrows-kmg-design-glyph-kmg-design.png" alt="external-up-arrow-arrows-kmg-design-glyph-kmg-design" />
                </button>
              </td>
              <td>
                <button
                  className='move-btn'
                  onClick={() => changePosition("down", advisory.id)}
                >
                  <img width="36" height="40" src="https://img.icons8.com/fluency-systems-filled/48/thick-arrow-pointing-down.png" alt="thick-arrow-pointing-down" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
