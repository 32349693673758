import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { Grid, TextField } from '@mui/material';
import Button from 'react-bootstrap/Button';
import 'react-quill/dist/quill.snow.css';
import APIService from '../../../../APIService';
import swal from 'sweetalert';

export const CommissionMembers = () => {

  const [commissionMembers, setCommissionMembers] = useState([]);
  const [heading, setHeading] = useState("");
  const [caption, setCaption] = useState("");
  const [description, setDescription] = useState("");
  const [fileUpload, setFileUpload] = useState("");

  const [commissionMembersId, setCommissionMembersId] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    APIService.get('commission_members').then((response) => {
      setCommissionMembers(response.data);
    });
  }, []);

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFileUpload(e.target.files[0]);
    }
  }

  const changePosition = (direction, itemId) => {

    APIService.post([], `change-position/${direction}/commission_members/${itemId}`)
      .then((response) => {
        // console.log(response);
        if (response.success) {
          APIService.get('commission_members').then((commission_members) => {
            setCommissionMembers(commission_members.data);
          });
        }
      })
  }

  const handleEdit = (itemId, heading, caption, description) => {
    setCommissionMembersId(itemId);
    setIsEditing(true);
    setHeading(heading)
    setCaption(caption)
    setDescription(description);
  };

  const handleDelete = (itemId) => {
    // e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "You want to delete this item?",
      icon: "warning",
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        APIService.delete(`commission_members/${itemId}`).then((response) => {
          swal("Success", response.message, "success", {
            buttons: false,
            timer: 2000,
          }).then((value) => {
            window.location.href = "/commission_members";
          });
        });
      }
    });
  };


  const handleSubmit = async (e) => {

    e.preventDefault();
    const formData = new FormData();

    formData.append("heading", heading);
    formData.append("caption", caption);
    formData.append("description", description);
    formData.append("image_upload", fileUpload);

    if (isEditing) {
      var response = await APIService.put(formData, `commission_members/${commissionMembersId}`);
    } else {
      var response = await APIService.post(formData, "commission_members");
    }

    if (response.success) {
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).then((value) => {
        window.location.href = "/commission_members";
      });
    } else {
      swal("Failed", response.message, "error");
    }
  }

  return (
    <div>
      <h2 className="organisation-head">Commission</h2>
      <h3 className="heading-commission">Commission Members</h3>

      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Heading</p>
            <TextField
              name="heading"
              label="Heading"
              variant="outlined"
              fullWidth
              value={heading}
              onChange={e => setHeading(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Caption</p>
            <TextField
              name="caption"
              label="Caption"
              variant="outlined"
              fullWidth
              value={caption}
              onChange={e => setCaption(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Upload File</p>
            <TextField
              name="upload-photo"
              type="file"
              onChange={handleFileChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={12} md={8}>
            <p id="labels">Description</p>
            <TextField
              fullWidth
              id="outlined-multiline-static"
              label="decription"
              multiline
              rows={3}
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
          </Grid>
        </Grid>
        <Button style={{ marginTop: 20 }} variant="primary" type='submit'>Submit</Button>{' '}
      </form>

      <Table striped bordered hover variant="light">
        <thead>
          <tr>
            <th>Sl No</th>
            <th>Heading</th>
            <th>Caption</th>
            <th>Description</th>
            <th>Edit</th>
            <th>Delete</th>
            <th>Move up</th>
            <th>Move down</th>
          </tr>
        </thead>
        <tbody>
          {commissionMembers.map((member, index) => (
            <tr key={member.id}>
              <td>{index + 1}</td>
              <td>{member.heading}</td>
              <td>{member.caption}</td>
              <td>{member.description}</td>
              <td><button className='tablebutton'
              onClick={() => handleEdit(member.id, member.heading, member.caption, member.description)}
              >Edit</button></td>
              <td><button className='tablebutton'
              onClick={() => handleDelete(member.id)}
              >Delete</button></td>
                    <td>
                          <button
                            className='move-btn'
                            onClick={() => changePosition("up", member.id)}
                          >
                            <img width="32" height="32" src="https://img.icons8.com/external-kmg-design-glyph-kmg-design/32/external-up-arrow-arrows-kmg-design-glyph-kmg-design.png" alt="external-up-arrow-arrows-kmg-design-glyph-kmg-design" />
                          </button>
                        </td>
                        <td>
                          <button
                            className='move-btn'
                            onClick={() => changePosition("down", member.id)}
                          >
                            <img width="36" height="40" src="https://img.icons8.com/fluency-systems-filled/48/thick-arrow-pointing-down.png" alt="thick-arrow-pointing-down" />
                          </button>
                        </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div >
  )
}
