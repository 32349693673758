import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { TextField } from '@mui/material';
import { Grid } from '@mui/material';
import 'react-quill/dist/quill.snow.css';
import APIService from '../../../../APIService';
import swal from 'sweetalert';

export const SCFMinutes = () => {

  const [scfMinutes, setScfMinutes] = useState([]);
  const [description, setDescription] = useState("");
  const [year, setYear] = useState("");
  const [fileUpload, setFileUpload] = useState("");

  const [scfMinutesId, setScfMinutesId] = useState("");
  const [isEditing, setIsEditing] = useState(false);


  useEffect(() => {
    APIService.get('scf_minutes').then((response) => {
      setScfMinutes(response.data);
    })
  }, []);

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFileUpload(e.target.files[0]);
    }
  }

  const changePosition = (direction, itemId) => {
    APIService.post([], `change-position/${direction}/scf_minutes/${itemId}`)
      .then((response) => {
        // console.log(response);
        if (response.success) {
          APIService.get('scf_minutes').then((response) => {
            setScfMinutes(response.data);
          });
        }
      })
  }


  const handleEdit = (itemId, year, description) => {

    setScfMinutesId(itemId);
    setIsEditing(true);
    setYear(year);
    setDescription(description);
  }

  const handleDelete = (itemId) => {

    // e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "You want to delete this item?",
      icon: "warning",
      dangerMode: true,
    })
      .then(willDelete => {
        if (willDelete) {
          APIService.delete(`scf_minutes/${itemId}`)
            .then(response => {
              swal("Success", response.message, "success", {
                buttons: false,
                timer: 2000,
              }).then((value) => {
                window.location.href = "/scf_minutes";
              });
            });
        }
      });
  }


  const handleSubmit = async (e) => {

    e.preventDefault();
    const formData = new FormData();

    formData.append("description", description);
    formData.append("year", year);
    formData.append("file_upload", fileUpload);

    if (isEditing) {
      var response = await APIService.put(formData, `scf_minutes/${scfMinutesId}`);
    } else {
      var response = await APIService.post(formData, 'scf_minutes');
    }


    if (response.success) {

      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).then((value) => {

        window.location.href = "/scf_minutes";
      });
    } else {

      swal("Failed", response.message, "error");
    }
  }

  return (
    <div>
      <h1>State Co-ordination Forum</h1>
      <h3 className="heading">SCF Minutes</h3>

      <form noValidate onSubmit={handleSubmit}>

        <Grid container spacing={3} style={{ marginTop: 2 }}>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Year</p>
            <TextField
              type="number"
              name="year"
              label="Year"
              variant="outlined"
              fullWidth
              value={year}
              onChange={e => setYear(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Upload File</p>
            <TextField
              name="upload-photo"
              type="file"
              onChange={handleFileChange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ marginTop: 20 }}>
          <Grid item xs={12} sm={6} lg={12} md={8}>
            <p id="labels">Description</p>
            <TextField
              fullWidth
              id="outlined-multiline-static"
              label="Description"
              multiline
              rows={3}
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
          </Grid>
        </Grid>

        <Button style={{ marginTop: 20 }} variant="primary" type="submit" >Submit</Button>{' '}
      </form>

      <div className="table">
        <Table striped bordered hover variant="light" id="table">
          <thead>
            <tr>
              <th className="table-no-aling">Sr.No. </th>
              <th>Description</th>
              <th>Year</th>
              <th>Edit</th>
              <th>Delete</th>
              <th>Move Up</th>
              <th>Move Down</th>
            </tr>
          </thead>
          <tbody>

            {scfMinutes.map((minute, index) => (
              <tr key={minute.id}>
                <td>{index + 1}</td>
                <td>{minute.description}</td>
                <td>{minute.year}</td>
                <td >
                  <Button variant="success" onClick={() => handleEdit(minute.id, minute.year,minute.description)}>Edit</Button>
                </td>
                <td >
                  <Button variant="danger" onClick={() => handleDelete(minute.id)}>Delete</Button>
                </td>
                <td>
                  <button className='move-btn'
                  onClick={() => changePosition("up", minute.id)}>
                    <img width="32" height="32" src="https://img.icons8.com/external-kmg-design-glyph-kmg-design/32/external-up-arrow-arrows-kmg-design-glyph-kmg-design.png" alt="external-up-arrow-arrows-kmg-design-glyph-kmg-design" /></button>
                </td>
                <td>
                  <button className='move-btn'
                   onClick={() => changePosition("down", minute.id)}>
                    <img width="36" height="40" src="https://img.icons8.com/fluency-systems-filled/48/thick-arrow-pointing-down.png" alt="thick-arrow-pointing-down" /></button>
                </td>
              </tr>
            ))}

          </tbody>
        </Table>
      </div>
    </div>
  );
};
