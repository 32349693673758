import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { Grid, TextField } from '@mui/material';
import Form from 'react-bootstrap/Form';
import APIService from '../../../../APIService';
import swal from 'sweetalert';

export default function FinalRegulations() {

  const [regulations, setRegulations] = useState([])
  const [description, setDescription] = useState("");
  const [option, setOption] = useState("");
  const [year, setYear] = useState("");
  const [fileEnglishUpload, setFileEnglishUpload] = useState("");
  const [fileMalayalamUpload, setFileMalayalamUpload] = useState("");
  const [fileGazzetUpload, setFileGazzetUpload] = useState("");

  const [regulationsId, setRegulationsId] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    APIService.get('final-regulations').then((response) => {
      setRegulations(response.data);
    });
  }, []);

  const handleFileChange = (e, field = "english") => {
    if (e.target.files) {
      switch (field) {
        case "english":
          setFileEnglishUpload(e.target.files[0]);
          break;
        case "malayalam":
          setFileMalayalamUpload(e.target.files[0]);
          break;
        default:
          setFileGazzetUpload(e.target.files[0]);
          break;
      }
    }
  }

  const changePosition = (direction, itemId) => {

    APIService.post([], `change-position/${direction}/final-regulations/${itemId}`)
      .then((response) => {
        // console.log(response);
        if (response.success) {
          APIService.get('final-regulations').then((response) => {
            setRegulations(response.data);
          });
        }
      })
  }

  const handleEdit = (itemId, description, option, year) => {
    setRegulationsId(itemId);
    setIsEditing(true);
    setDescription(description);
    setOption(option);
    setYear(year);
  };


  const handleDelete = (itemId) => {
    // e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "You want to delete this item?",
      icon: "warning",
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        APIService.delete(`final-regulations/${itemId}`).then((response) => {
          swal("Success", response.message, "success", {
            buttons: false,
            timer: 2000,
          }).then((value) => {
            window.location.href = "/final_regulations";
          });
        });
      }
    });
  }

  const handleSubmit = async (e) => {

    e.preventDefault();
    const formData = new FormData();

    formData.append("description", description);
    formData.append("year", year);
    formData.append("option", option);
    formData.append("english_upload", fileEnglishUpload);
    formData.append("malayalam_upload", fileMalayalamUpload);
    formData.append("gazette_upload", fileGazzetUpload);

    if (isEditing) {
      var response = await APIService.put(formData, `final-regulations/${regulationsId}`);
    } else {
      var response = await APIService.post(formData, "final-regulations");
    }

    if (response.success) {

      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).then((value) => {

        window.location.href = "/final_regulations";
      });
    } else {

      swal("Failed", response.message, "error");
    }
  }

  return (
    <div>
      <div className='final-regulation'>
        <h3 className='heading'>Final Regulations</h3>
        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3} style={{ marginTop: 10 }}>
            <Grid item xs={12} sm={6} lg={6} >
              <p id="labels">Select Options</p>
              <Form.Select
                aria-label="Default select example"
                value={option}
                onChange={e => setOption(e.target.value)}
              >
                <option value="" disabled>Select</option>
                <option value="Tariff Regulations">Tariff Regulations</option>
                <option value="Conduct of Business Regulations">Conduct of Business Regulations</option>
                <option value="CGRF and Ombudsman Regulations">CGRF and Ombudsman Regulations</option>
                <option value="Licensing Regulations">Licensing Regulations</option>
                <option value="Renewable Energy Regulations">Renewable Energy Regulations</option>
                <option value="Distribution Licensee Regulations">Distribution Licensee Regulations</option>
                <option value="Generating companies Regulations">Generating companies Regulations</option>
                <option value="Connectivity and Open Access Regulations">Connectivity and Open Access Regulations</option>
                <option value="Electricity Supply Code and Amendments">Electricity Supply Code and Amendments</option>
                <option value="Electricity Grid Code and Amendments">Electricity Grid Code and Amendments</option>
                <option value="Compliance and Audit Regulations">Compliance and Audit Regulations</option>
                <option value="Administrative Regulations">Administrative Regulations</option>
                <option value="Miscellaneous Regulations">Miscellaneous Regulations</option>
              </Form.Select>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <p id="labels">Year</p>
              <TextField
                type="number"
                name="year"
                label="Year"
                variant="outlined"
                fullWidth
                value={year}
                onChange={e => setYear(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: 10 }}>
            <Grid item xs={12} sm={6} lg={12} md={8}>
              <p id="labels">Decription</p>
              <TextField
                fullWidth
                id="outlined-multiline-static"
                label="Name"
                multiline
                rows={3}
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: 10 }}>
            <Grid item xs={12} sm={6} lg={4}>
              <p id="labels">Upload English</p>
              <TextField
                name="upload-photo"
                type="file"
                onChange={(e) => handleFileChange(e, "english")}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <p id="labels">Upload Malayalam</p>
              <TextField
                name="upload-photo"
                type="file"
                onChange={(e) => handleFileChange(e, "malayalam")}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <p id="labels">Upload Gazette</p>
              <TextField
                name="upload-photo"
                type="file"
                onChange={(e) => handleFileChange(e, "gazette")}
              />
            </Grid>
          </Grid>
          <Button style={{ marginTop: 20 }} variant="primary" type="submit" >Submit</Button>{' '}
        </form>
        <Table striped bordered hover variant="light">
          <thead>
            <tr>
              <th className='table-no-aling'>Sr.No.</th>
              <th>Option</th>
              <th>Decription</th>
              <th>Year</th>
              <th>Edit</th>
              <th>Delete</th>
              <th>Move Up</th>
              <th>Move Down</th>
            </tr>
          </thead>
          <tbody>
            {regulations.map((regulation, index) => (
              <tr key={regulation.id}>
                <td>{index + 1}</td>
                <td>{regulation.option}</td>
                <td>{regulation.description}</td>
                <td>{regulation.year}</td>
                <td><button className='tablebutton'
                  onClick={() => handleEdit(regulation.id, regulation.description, regulation.option, regulation.year)}
                >Edit</button></td>
                <td><button className='tablebutton'
                  onClick={() => handleDelete(regulation.id)}
                >Delete</button></td>
                <td><button className='move-btn'
                  onClick={() => changePosition("up", regulation.id)}
                ><img width="32" height="32" src="https://img.icons8.com/external-kmg-design-glyph-kmg-design/32/external-up-arrow-arrows-kmg-design-glyph-kmg-design.png" alt="external-up-arrow-arrows-kmg-design-glyph-kmg-design" /></button></td>
                <td><button className='move-btn'
                  onClick={() => changePosition("down", regulation.id)}
                ><img width="36" height="40" src="https://img.icons8.com/fluency-systems-filled/48/thick-arrow-pointing-down.png" alt="thick-arrow-pointing-down" /></button></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  )
}
