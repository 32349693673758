import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { Grid, TextField } from '@mui/material';
import APIService from '../../../../APIService';
import swal from 'sweetalert';

export const OtherLinks = () => {

  const [otherLinks, setOtherLinks] = useState([])
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [fileUpload, setFileUpload] = useState("");

  const [otherLinksId, setOtherLinksId] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    APIService.get('other_links').then((response) => {
      setOtherLinks(response.data);
    });
  }, []);

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFileUpload(e.target.files[0]);
    }
  }

  const changePosition = (direction, itemId) => {

    APIService.post([], `change-position/${direction}/other_links/${itemId}`)
      .then((response) => {
        // console.log(response);
        if (response.success) {
          APIService.get('other_links').then((response) => {
            setOtherLinks(response.data);
          });
        }
      })
  }

  const handleEdit = (itemId, title, link) => {
    setOtherLinksId(itemId);
    setIsEditing(true);
    setTitle(title);
    setLink(link);

  }

  const handleDelete = (itemId) => {

    // e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "You want to delete this item?",
      icon: "warning",
      dangerMode: true,
    })
      .then(willDelete => {
        if (willDelete) {
          APIService.delete(`other_links/${itemId}`)
            .then(response => {
              swal("Success", response.message, "success", {
                buttons: false,
                timer: 2000,
              }).then((value) => {
                window.location.href = "/other_links";
              });
            });
        }
      });
  }


  const handleSubmit = async (e) => {

    e.preventDefault();
    const formData = new FormData();

    formData.append("title", title);
    formData.append("link", link);
    formData.append("logo_upload", fileUpload);

    if (isEditing) {

      var response = await APIService.put(formData, `other_links/${otherLinksId}`);
    } else {

      var response = await APIService.post(formData, 'other_links');
    }

    if (response.success) {
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).then((value) => {
        window.location.href = "/other_links";
      });
    } else {
      swal("Failed", response.message, "error");
    }
  }

  return (
    <div>
      <h2 className="organisation-head">Other Links</h2>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          {/* <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">SI.No</p>
            <TextField
              name="si_no"
              label="SI.No"
              variant="outlined"
              fullWidth
            />
          </Grid> */}
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Link</p>
            <TextField
              name="link"
              label="link"
              variant="outlined"
              fullWidth
              value={link}
              onChange={e => setLink(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Upload Logo</p>
            <TextField
              name="upload-photo"
              type="file"
              onChange={handleFileChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={12} md={8}>
            <p id="labels">Title</p>
            <TextField
              fullWidth
              id="outlined-multiline-static"
              label="Title"
              multiline
              rows={3}
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
          </Grid>
        </Grid>
        <Button style={{ marginTop: 20 }} variant="primary" type='submit'>Submit</Button>{' '}
      </form>

      <Table striped bordered hover variant="light">
        <thead>
          <tr>
            <th>Sl No</th>
            <th>Title</th>
            <th>Link</th>
            <th>Edit</th>
            <th>Delete</th>
            <th>Move Up</th>
            <th>Move Down</th>
          </tr>
        </thead>
        <tbody>
          {otherLinks.map((link, index) => (
            <tr key={link.id}>
              <td>{index + 1}</td>
              <td>{link.title}</td>
              <td>{link.link}</td>
              <td><button className='tablebutton'
                onClick={() => handleEdit(link.id, link.title, link.link)}
              >Edit</button></td>
              <td><button className='tablebutton'
                onClick={() => handleDelete(link.id)}
              >Delete</button></td>
              <td>
                <button
                  className='move-btn'
                  onClick={() => changePosition("up", link.id)}
                >
                  <img width="32" height="32" src="https://img.icons8.com/external-kmg-design-glyph-kmg-design/32/external-up-arrow-arrows-kmg-design-glyph-kmg-design.png" alt="external-up-arrow-arrows-kmg-design-glyph-kmg-design" />
                </button>
              </td>
              <td>
                <button
                  className='move-btn'
                  onClick={() => changePosition("down", link.id)}
                >
                  <img width="36" height="40" src="https://img.icons8.com/fluency-systems-filled/48/thick-arrow-pointing-down.png" alt="thick-arrow-pointing-down" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
