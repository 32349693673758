import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { Grid, TextField } from '@mui/material';
import APIService from '../../../../APIService';
import swal from 'sweetalert';

export const OtherErc = () => {
  const [otherErcs, setOtherErcs] = useState([])
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");

  const [otherErcId, setOtherErcId] = useState("");
  const [isEditing, setIsEditing] = useState(false);


  useEffect(() => {
    APIService.get('other_ercs').then((response) => {
      setOtherErcs(response.data);
    });
  }, []);


  const changePosition = (direction, itemId) => {

    APIService.post([], `change-position/${direction}/other_ercs/${itemId}`)
      .then((response) => {
        // console.log(response);
        if (response.success) {
          APIService.get('other_ercs').then((response) => {
            setOtherErcs(response.data);
          });
        }
      })
  }


  const handleEdit = (itemId, title, link) => {
    setOtherErcId(itemId);
    setIsEditing(true);
    setTitle(title);
    setLink(link);
  };

  const handleDelete = (itemId) => {
    // e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "You want to delete this item?",
      icon: "warning",
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        APIService.delete(`other_ercs/${itemId}`).then((response) => {
          swal("Success", response.message, "success", {
            buttons: false,
            timer: 2000,
          }).then((value) => {
            window.location.href = "/other_ercs";
          });
        });
      }
    });
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", title);
    formData.append("link", link);

    if (isEditing) {
      var response = await APIService.put(formData, `other_ercs/${otherErcId}`);
    } else {
      var response = await APIService.post(formData, "other_ercs");
    }



    if (response.success) {
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).then((value) => {
        window.location.href = "/other_ercs";
      });
    } else {
      swal("Failed", response.message, "error");
    }
  }
  return (
    <div>
      <h2 className="organisation-head">Other ERC's</h2>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          {/* <Grid item xs={12} sm={6} lg={4}>
          <p id="labels">SI.No</p>
          <TextField
            name="si_no"
            label="SI.No"
            variant="outlined"
            fullWidth
          />
        </Grid> */}
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Link</p>
            <TextField
              name="link"
              label="link"
              variant="outlined"
              fullWidth
              value={link}
              onChange={e => setLink(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={12} md={8}>
            <p id="labels">Title</p>
            <TextField
              fullWidth
              id="outlined-multiline-static"
              label="Title"
              multiline
              rows={3}
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
          </Grid>
        </Grid>
        <Button style={{ marginTop: 20 }} variant="primary" type='submit'>Submit</Button>{' '}
      </form>
      <Table striped bordered hover variant="light">
        <thead>
          <tr>
            <th>Sl No</th>
            <th>Title</th>
            <th>Link</th>
            <th>Edit</th>
            <th>Delete</th>
            <th>Move Up</th>
            <th>Move Down</th>
          </tr>
        </thead>
        <tbody>
          {otherErcs.map((ercs, index) => (
            <tr key={ercs.id}>
              <td>{index + 1}</td>
              <td>{ercs.title}</td>
              <td>{ercs.link}</td>
              <td><button className='tablebutton'  onClick={() => handleEdit(ercs.id, ercs.title, ercs.added_on)}

               >Edit</button></td>

              <td><button className='tablebutton' onClick={() => handleDelete(ercs.id)}
              >Delete</button></td>


              <td><button className='move-btn'
               onClick={() => changePosition("up", ercs.id)}
               ><img width="32" height="32" src="https://img.icons8.com/external-kmg-design-glyph-kmg-design/32/external-up-arrow-arrows-kmg-design-glyph-kmg-design.png" alt="external-up-arrow-arrows-kmg-design-glyph-kmg-design" /></button></td>
              <td><button className='move-btn'
              onClick={() => changePosition("down", ercs.id)}
              ><img width="36" height="40" src="https://img.icons8.com/fluency-systems-filled/48/thick-arrow-pointing-down.png" alt="thick-arrow-pointing-down" /></button></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
