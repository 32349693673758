import React, { useState, useEffect } from 'react';
import { Grid, TextField } from '@mui/material';
import Button from 'react-bootstrap/Button';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import APIService from '../../../../APIService';
import swal from 'sweetalert';

export const Commission = () => {

  const [banner, setBannerFile] = useState("");
  const [image, setImageFile] = useState("");
  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");
  const [functions, setFunctions] = useState("");

  useEffect(() => {
    APIService.get('commission')
      .then((response) => {
        setHeading(response.commission_heading);
        setDescription(response.commission_description);
        setFunctions(response.commission_functions);
      });
  }, []);

  const handleFileChange = (e, type = "banner") => {
    if (e.target.files) {

      if (type === "banner") {

        setBannerFile(e.target.files[0]);
      } else {

        setImageFile(e.target.files[0]);
      }
    }
  }

  const handleSubmit = async (e) => {

    e.preventDefault();
    const formData = new FormData();

    formData.append("commission_banner", banner);
    formData.append("commission_image", image);
    formData.append("commission_heading", heading);
    formData.append("commission_description", description);
    formData.append("commission_functions", functions);

    const response = await APIService.post(formData, 'commission');

    if (response.success) {
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).then((value) => {
        window.location.href = "/commission";
      });
    } else {
      swal("Failed", response.message, "error");
    }
  }

  return (

    <div>
      <h2 className="organisation-head">Commission</h2>

      <h4 style={{ marginTop: 30 }}>About Section Banner</h4>

      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={12} lg={12}>
            <p id="labels">Upload Image</p>
            <TextField
              name="upload-photo"
              type="file"
              onChange={(e) => handleFileChange(e, "banner")}
            />
          </Grid>
        </Grid>

        <h4 style={{ marginTop: 30 }}>The Commission Page</h4>

        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Heading</p>
            <TextField
              name="heading"
              label="Heading"
              variant="outlined"
              fullWidth
              value={heading}
              onChange={e => setHeading(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Upload Image</p>
            <TextField
              name="upload-photo"
              type="file"
              onChange={(e) => handleFileChange(e, "image")}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={12} md={8}>
            <p id="labels">Decription</p>
            <ReactQuill
              style={{ height: 200 }}
              value={description}
              onChange={setDescription}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} lg={12} md={8} style={{ marginTop: 70 }}>
          <p id="labels">Functions</p>
          <ReactQuill
            style={{ height: 200 }}
            value={functions}
            onChange={setFunctions}
          />
        </Grid>
        <Button style={{ marginTop: 60 }} variant="primary" type='submit'>Submit</Button>{' '}
      </form>
    </div>
  )
}
