import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';
import Orders from '../views/dashboard/components/Orders/Orders';
import Shedule from '../views/dashboard/components/Shedule/Shedule';
import PressRelease from '../views/dashboard/components/Press Release/PressRelease';
// import ombudsman from '../views/dashboard/components/Ombudsman/Ombudsman';
import Petitions from '../views/dashboard/components/Petition/Petitions';
import License from '../views/dashboard/components/License/License';
import CGRF from '../views/dashboard/components/CGRF/CGRF';
import Archives from '../views/dashboard/components/Archives/Archives';
import StateAdvisoryCommettee from '../views/dashboard/components/StateAdvisoryCommettie/StateAdvisoryCommettee';
import Career from '../views/dashboard/components/Career/Career';
import { ConsumerAdvocacy } from '../views/dashboard/components/ConsumerAdvocacyCell/ConsumerAdvocacy';
import { DashboardKserc } from '../views/dashboard/components/Dashboard/DashboardKserc';
import { OtherDocuments } from '../views/dashboard/components/OtherDocuments/OtherDocuments';
import { Quotations } from '../views/dashboard/components/Quotations/Quotations';
import { Reports } from '../views/dashboard/components/Reports/Reports';
import { StateCordination } from '../views/dashboard/components/StateCoordinationForum/StateCordination';
import { Rti } from '../views/dashboard/components/RTI/Rti';
import { Commission } from '../views/dashboard/components/Commission/Commission';
import { Faq } from '../views/dashboard/components/Faq/Faq';
import { History } from '../views/dashboard/components/History/History';
import { Acts } from '../views/dashboard/components/Acts/Acts';
import { Policies } from '../views/dashboard/components/Policies/Policies';
import { AdviceToGok } from '../views/dashboard/components/Advice to GOK/AdviceToGok';
import { RepealedRegulation } from '../views/dashboard/components/Repealed Regulation/RepealedRegulation';
import { OtherErc } from '../views/dashboard/components/Other ERC/OtherErc';
import { OtherLinks } from '../views/dashboard/components/OtherLinks/OtherLinks';
import Rules from '../views/dashboard/components/Rules/Rules';

/* ****UPDATED***** */
import DraftRegulations from '../views/dashboard/components/DraftRegulations/DraftRegulations';
import FinalRegulations from '../views/dashboard/components/FinalRegulations/FinalRegulations';

// import Gallery from '../views/dashboard/components/Gallery/Gallery';
import GalleryThumbnail from '../views/dashboard/components/GalleryThumbnail/GalleryThumbnail';
import GalleryPhoto from '../views/dashboard/components/GalleryPhoto/GalleryPhoto';

// import { Startup } from '../views/dashboard/components/Startup/Startup';
import { StartupThumbnail } from '../views/dashboard/components/StartupThumbnail/StartupThumbnail';
import StartupPhoto from '../views/dashboard/components/StartupPhoto/StartupPhoto';

// import { Events } from '../views/dashboard/components/Events/Events';
import { EventsThumbnail } from '../views/dashboard/components/EventsThumbnail/EventsThumbnail';
import EventsPhoto from '../views/dashboard/components/EventsPhoto/EventsPhoto';

import { News } from '../views/dashboard/components/News/News';
// import { NewsThumbnail } from '../views/dashboard/components/NewsThumbnail/NewsThumbnail';
// import { NewsPhoto } from '../views/dashboard/components/NewsPhotos/NewsPhoto';
import SACMinutes from '../views/dashboard/components/StateAdvisoryCommettie/SACMinutes';
import { SCFMinutes } from '../views/dashboard/components/StateCoordinationForum/SCFMinutes';
import { CommissionMembers } from '../views/dashboard/components/Commission/CommissionMembers';
import { Announcements } from '../views/dashboard/components/Announcements/Announcement';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
/* ****Pages***** */
const Dashboard = Loadable(lazy(() => import('../views/dashboard/Dashboard')))
const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));

const Router = [
  {
    path: '/',
    element: <Login />,
    children: [
      { path: '404', element: <Error /> },
    ],
  },
  {
    path: '/',
    element: <FullLayout />,
    children: [

      // { path: '/', element: <GalleryThumbnail /> }, 
      // { path: '/', element: <Navigate to="/gallery" /> },
      // { path: '/login', exact: true, element: <Login /> },
      { path: '/dashboard', exact: true, element: <Dashboard /> },
      { path: '/orders', exact: true, element: <Orders /> },
      { path: '/schedule', exact: true, element: <Shedule /> },
      { path: '/pressrelease', exact: true, element: <PressRelease /> },
      // { path: '/ombudsman', exact: true, element:<Ombudsman/> },
      { path: '/petitions', exact: true, element: <Petitions /> },
      { path: '/license', exact: true, element: <License /> },
      { path: '/cgrf', exact: true, element: <CGRF /> },
      { path: '/archives', exact: true, element: <Archives /> },
      { path: '/rti', exact: true, element: <Rti /> },

      { path: '/stateadvisory', exact: true, element: <StateAdvisoryCommettee /> },
      { path: '/sac_minutes', exact: true, element: <SACMinutes /> },

      { path: '/careers', exact: true, element: <Career /> },
      { path: '/dashboard-kserc', exact: true, element: <DashboardKserc /> },
      { path: '/other_documents', exact: true, element: <OtherDocuments /> },
      { path: '/quotations', exact: true, element: <Quotations /> },
      { path: '/reports', exact: true, element: <Reports /> },
      { path: '/consumer_advocacy_cell', exact: true, element: <ConsumerAdvocacy /> },

      { path: '/state-coordination', exact: true, element: <StateCordination /> },
      { path: '/scf_minutes', exact: true, element: <SCFMinutes /> },

      { path: '/commission', exact: true, element: <Commission /> },
      { path: '/commission_members', exact: true, element: <CommissionMembers /> },

      { path: '/history', exact: true, element: <History /> },
      { path: '/faq', exact: true, element: <Faq /> }, { path: '/acts', exact: true, element: <Acts /> },
      { path: '/policies', exact: true, element: <Policies /> },
      { path: '/advice_to_gok', exact: true, element: <AdviceToGok /> },
      { path: '/repealed_regulations', exact: true, element: <RepealedRegulation /> },
      { path: '/rules', exact: true, element: <Rules /> },
      { path: '/other_ercs', exact: true, element: <OtherErc /> },
      { path: '/other_links', exact: true, element: <OtherLinks /> },

      /* ****UPDATED***** */
      { path: '/draft_regulations', exact: true, element: <DraftRegulations /> },
      { path: '/final_regulations', exact: true, element: <FinalRegulations /> },

      { path: '/gallery', exact: true, element: <GalleryThumbnail /> },
      { path: '/gallery/:id', exact: true, element: <GalleryPhoto /> },

      { path: '/events', exact: true, element: <EventsThumbnail /> },
      { path: '/events/:id', exact: true, element: <EventsPhoto /> },

      { path: '/startup', exact: true, element: <StartupThumbnail /> },
      { path: '/startup/:id', exact: true, element: <StartupPhoto /> },

      { path: '/news', exact: true, element: <News /> },
      { path: '/announcement', exact: true, element: <Announcements /> },

      // { path: '/news_thumbnail', exact: true, element: <NewsThumbnail /> },
      // { path: '/news/:id', exact: true, element: <NewsPhoto /> },
    ],
  },
];

export default Router;
