import React, { useState, useEffect } from 'react';
import { Grid, TextField } from '@mui/material';
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import swal from 'sweetalert';
import { LoadingButton } from '@mui/lab';
import APIService from '../../../../APIService';
import Form from 'react-bootstrap/Form';


export default function Petitions() {


  const [petitions, setPetitions] = useState([]);
  const [petitionId, setPetitionId] = useState("");
  const [title, setTitle] = useState("");
  const [opno, setOpno] = useState("");
  const [fileUpload, setFileUpload] = useState("");
  const [date, setDate] = useState("");

  const [otherPetitions, setOtherPetitions] = useState([]);
  const [otherTitle, setOtherTitle] = useState("");
  const [otherOpno, setOtherOpno] = useState("");
  const [otherFileUpload, setOtherFileUpload] = useState("");
  const [otherDate, setOtherDate] = useState("");

  const [licensees, setLicensees] = useState([]);
  const [selectedLicensee, setSelectedLicensee] = useState('');
  const [selectedOtherLicensee, setSelectedOtherLicensee] = useState('');

  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    APIService.get('licensees').then((response) => {
      setLicensees(response.data);
    })
  }, [])


  useEffect(() => {
    APIService.get('petitions').then((response) => {
      setPetitions(response.petitions);
    });
    APIService.get('otherpetitions').then((response) => {
      setOtherPetitions(response.petitions);
    });
  }, []);

  const handleFileChange = (e, option = "petitions") => {

    if (e.target.files) {
      if (option === "otherpetitions") {
        // console.log(option);
        // console.log(e.target.files);
        setOtherFileUpload(e.target.files[0]);
        // console.log(otherFileUpload);
      } else {
        setFileUpload(e.target.files[0]);
      }
    }
  }


  const changePosition = (direction, itemId, option = "petitions") => {

    APIService.post([], `change-position/${direction}/${option}/${itemId}`)
      .then((response) => {
        // console.log(response);
        if (response.success) {
          if (option === "otherpetitions") {

            APIService.get('otherpetitions').then((response) => {
              setOtherPetitions(response.petitions);
            });
          } else {

            APIService.get('petitions').then((response) => {
              setPetitions(response.petitions);
            });
          }
        }
      })
  }

  const handleEdit = (itemId, title, opno, date, licensee_id, option = "petitions") => {

    setPetitionId(itemId);
    setIsEditing(true);

    if (option === "otherpetitions") {

      setOtherTitle(title);
      setOtherOpno(opno);
      setOtherDate(date)
      setSelectedOtherLicensee(licensee_id);
    } else {

      setTitle(title);
      setOpno(opno);
      setDate(date)
      setSelectedLicensee(licensee_id);
    }
  }

  const handleDelete = (itemId, option = "petitions") => {

    // e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "You want to delete this item?",
      icon: "warning",
      dangerMode: true,
    })
      .then(willDelete => {
        if (willDelete) {
          APIService.delete(`${option}/${itemId}`)
            .then(response => {
              swal("Success", response.message, "success", {
                buttons: false,
                timer: 2000,
              }).then((value) => {
                window.location.href = "/petitions";
              });
            });
        }
      });
  }

  const handleSubmit = async (e, option = "petitions") => {

    e.preventDefault();

    // console.log(option);
    const formData = new FormData();

    if (option === "otherpetitions") {

      formData.append("opno", otherOpno);
      formData.append("title", otherTitle);
      formData.append("date", otherDate);
      formData.append("file_upload", otherFileUpload);
      formData.append("licensee_id", selectedOtherLicensee)
    } else {

      formData.append("opno", opno);
      formData.append("title", title);
      formData.append("date", date);
      formData.append("file_upload", fileUpload);
      formData.append("licensee_id", selectedLicensee)
    }

    if (isEditing) {
      var response = await APIService.put(formData, `${option}/${petitionId}`);
    } else {
      var response = await APIService.post(formData, option);
    }

    // const response = await APIService.post(formData, option);

    if (response.success) {
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).then((value) => {
        window.location.href = "/petitions";
      });
    } else {
      swal("Failed", response.message, "error");
    }

  };

  return (
    <div>
      <h2 className="organisation-head">Petitions</h2>
      <h3 className='title'>ARR Petitions</h3>
      <form className="login-form" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          {/* <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Sr.No</p>
            <TextField
              name="sr_no"
              label="sr.no"
              variant="outlined"
              fullWidth
            />
          </Grid> */}
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Op.No</p>
            <TextField
              name="op_no"
              label="Op.No"
              variant="outlined"
              fullWidth
              value={opno}
              onChange={e => setOpno(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Upload File</p>
            <TextField
              name="upload-photo"
              type="file"
              onChange={handleFileChange}
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Date</p>
            <TextField
              type="date"
              name="date"
              variant="outlined"
              fullWidth
              value={date}
              onChange={e => setDate(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={6} className="order-select-option">
            <p id="labels">Select Licensees</p>
            <Form.Select
              aria-label="Default select example"
              value={selectedLicensee}
              onChange={e => setSelectedLicensee(e.target.value)}
            >

              <option value="" disabled>Select an Option</option>
              {licensees.map((licensee, index) => (
                <option value={licensee.id} key={index}>
                  {licensee.heading}
                </option>
              ))}
            </Form.Select>
          </Grid>

        </Grid>
        <Grid container spacing={3} style={{ marginTop: 30 }}>
          <Grid item xs={12} sm={6} lg={12} md={8}>
            <p id="labels">Title</p>
            <TextField
              fullWidth
              id="outlined-multiline-static"
              label="Title"
              multiline
              rows={3}
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
          </Grid>
        </Grid>
        <div className='login-btn' style={{ marginTop: 20 }}>
          <LoadingButton type="submit" variant="contained" id='login-btn'>
            Submit
          </LoadingButton>
        </div>
        {/* <Button style={{ marginTop: 20 }} variant="primary">Submit</Button>{' '} */}
      </form>

      <Table striped bordered hover variant="light">
        <thead>
          <tr>
            <th className="table-no-aling">Sr.No. </th>
            <th>Op.No</th>
            <th>Title</th>
            <th>Date</th>
            <th>Edit</th>
            <th>Delete</th>
            <th>Move Up</th>
            <th>Move Down</th>
          </tr>
        </thead>
        <tbody>
          {petitions.map((petition, index) => (
            <tr key={petition.id}>
              <td>{index + 1}</td>
              <td>{petition.opno}</td>
              <td>{petition.title}</td>
              <td>{petition.date}</td>
              <td>
                <button
                  className='tablebutton'
                  onClick={() => handleEdit(petition.id, petition.title, petition.opno, petition.date, petition.licensee_id)}
                >
                  Edit
                </button>
              </td>
              <td>
                <button
                  className='tablebutton'
                  onClick={() => handleDelete(petition.id)}
                >
                  Delete
                </button>
              </td>
              <td>
                <button
                  className='move-btn'
                  onClick={() => changePosition("up", petition.id)}
                >
                  <img width="32" height="32" src="https://img.icons8.com/external-kmg-design-glyph-kmg-design/32/external-up-arrow-arrows-kmg-design-glyph-kmg-design.png" alt="external-up-arrow-arrows-kmg-design-glyph-kmg-design" />
                </button>
              </td>
              <td>
                <button
                  className='move-btn'
                  onClick={() => changePosition("down", petition.id)}
                >
                  <img width="36" height="40" src="https://img.icons8.com/fluency-systems-filled/48/thick-arrow-pointing-down.png" alt="thick-arrow-pointing-down" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <h3 className='title'>Other Petitions</h3>

      <form className="login-form" noValidate onSubmit={e => handleSubmit(e, "otherpetitions")}>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          {/* <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Sr.No</p>
            <TextField
              name="sr_no"
              label="sr.no"
              variant="outlined"
              fullWidth
            />
          </Grid> */}
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Op.No</p>
            <TextField
              name="op_no"
              label="Op.No"
              variant="outlined"
              fullWidth
              value={otherOpno}
              onChange={e => setOtherOpno(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Upload File</p>
            <TextField
              name="upload-photo"
              type="file"
              onChange={e => handleFileChange(e, "otherpetitions")}
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Date</p>
            <TextField
              type="date"
              name="date"
              variant="outlined"
              fullWidth
              value={otherDate}
              onChange={e => setOtherDate(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={6} className="order-select-option">
            <p id="labels">Select Licensee</p>
            <Form.Select
              aria-label="Default select example"
              value={selectedOtherLicensee}
              onChange={e => setSelectedOtherLicensee(e.target.value)}
            >
              <option value="">Select a Licensee</option>
              {licensees.map(licensee => (
                <option key={licensee.id} value={licensee.id}>
                  {licensee.heading}
                </option>
              ))}
            </Form.Select>
          </Grid>

        </Grid>
        <Grid container spacing={3} style={{ marginTop: 30 }}>
          <Grid item xs={12} sm={6} lg={12} md={8}>
            <p id="labels">Title</p>
            <TextField
              fullWidth
              id="outlined-multiline-static"
              label="Title"
              multiline
              rows={3}
              value={otherTitle}
              onChange={e => setOtherTitle(e.target.value)}
            />
          </Grid>
        </Grid>
        <Button style={{ marginTop: 20 }} variant="primary" type='submit'>Submit</Button>{' '}
      </form>
      <Table striped bordered hover variant="light">
        <thead>
          <tr>
            <th className="table-no-aling">Sr.No.</th>
            <th>Op.No</th>
            <th>Title</th>
            <th>Date</th>
            <th>Upload/Edit</th>
            <th>Delete</th>
            <th>Move Up</th>
            <th>Move Down</th>
          </tr>
        </thead>
        <tbody>
          {otherPetitions.map((petition, index) => (
            <tr key={petition.id}>
              <td>{index + 1}</td>
              <td>{petition.opno}</td>
              <td>{petition.title}</td>
              <td>{petition.date}</td>
              <td>
                <button
                  className='tablebutton'
                  onClick={() => handleEdit(petition.id, petition.title, petition.opno, petition.date, petition.licensee_id, "otherpetitions")}
                >
                  Edit
                </button>
              </td>
              <td>
                <button
                  className='tablebutton'
                  onClick={() => handleDelete(petition.id, "otherpetitions")}
                >
                  Delete
                </button>
              </td>
              <td>
                <button
                  className='move-btn'
                  onClick={() => changePosition("up", petition.id, "otherpetitions")}
                >
                  <img width="32" height="32" src="https://img.icons8.com/external-kmg-design-glyph-kmg-design/32/external-up-arrow-arrows-kmg-design-glyph-kmg-design.png" alt="external-up-arrow-arrows-kmg-design-glyph-kmg-design" />
                </button>
              </td>
              <td>
                <button
                  className='move-btn'
                  onClick={() => changePosition("down", petition.id, "otherpetitions")}
                >
                  <img width="36" height="40" src="https://img.icons8.com/fluency-systems-filled/48/thick-arrow-pointing-down.png" alt="thick-arrow-pointing-down" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
