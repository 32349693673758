import React, { useState, useEffect } from 'react';
import { Grid, TextField } from '@mui/material';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import APIService from '../../../../APIService';
import swal from 'sweetalert';
import { useParams } from 'react-router';

export default function GalleryPhoto() {

  const params = useParams();
  const thumbnailId = params.id;

  const [galleries, setGalleries] = useState([]);
  const [caption, setCaption] = useState("");
  const [fileUpload, setFileUpload] = useState("");


  const [galleryId, setGalleryId] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    APIService.get('gallery_thumbnail/' + thumbnailId).then((response) => {
      setGalleries(response.data);
    })
  }, []);

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFileUpload(e.target.files[0]);
    }
  }

  const handleEdit = (itemId, caption, description) => {
    setGalleryId(itemId);
    setIsEditing(true);
    setCaption(caption);
    // setDescription(description)
  }


  const handleDelete = (itemId) => {

    // e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "You want to delete this item?",
      icon: "warning",
      dangerMode: true,
    })
      .then(willDelete => {
        if (willDelete) {
          APIService.delete(`gallery/${itemId}`)
            .then(response => {
              swal("Success", response.message, "success", {
                buttons: false,
                timer: 2000,
              }).then((value) => {
                window.location.href = "/gallery/" + thumbnailId;
              });
            });
        }
      });
  }

  const handleSubmit = async (e) => {

    e.preventDefault();
    const formData = new FormData();

    formData.append("caption", caption);
    formData.append("thumbnail_id", thumbnailId);
    // formData.append("description", description);
    formData.append("image_upload", fileUpload);

    if (isEditing) {

      var response = await APIService.put(formData, `gallery/${galleryId}`);
    } else {

      var response = await APIService.post(formData, 'gallery');
    }

    if (response.success) {

      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).then((value) => {

        window.location.href = "/gallery/" + thumbnailId;
      });
    } else {

      swal("Failed", response.message, "error");
    }
  }

  return (
    <div>

      <form noValidate onSubmit={handleSubmit}>
        <h3 className='mt-4'>Photo Upload (Gallery)</h3>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Caption</p>
            <TextField
              name="Caption"
              label="Caption"
              variant="outlined"
              fullWidth
              value={caption}
              onChange={e => setCaption(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} style={{ marginTop: 30 }} >
          <p id="labels">Upload Image</p>
          <TextField
            name="upload-photo"
            type="file"
            onChange={handleFileChange}
          />
        </Grid>
        <Button style={{ marginTop: 20 }} variant="primary" type="submit">Upload</Button>{' '}
      </form>

      <Table responsive="sm mt-5">
        <thead>
          <h3>Photo List</h3>
          <tr>
            <th>Sr.No</th>
            <th>Caption</th>
            <th>Image</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {galleries.map((gallery, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{gallery.caption}</td>
              <td>{gallery.description}</td>
              <td><button className='tablebutton'
                onClick={() => handleEdit(gallery.id, gallery.caption, gallery.description)}
              >Edit</button></td>
              <td><button className='tablebutton'
                onClick={() => handleDelete(gallery.id)}
              >Delete</button></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
