export default {

  base_url: "https://mal.erckerala.org/api",
  // base_url: "https://erckerala.test/api",

  get: async function (endpoint) {
    const data = await fetch(`${this.base_url}/${endpoint}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
    });
    return await data.json();
  },
  post: async function (formData, endpoint) {
    const data = await fetch(`${this.base_url}/${endpoint}`, {
      method: 'POST',
      headers: {
        // 'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: formData
    });
    return await data.json();
  },
  put: async function (formData, endpoint) {
    formData.append("_method", "PUT");
    const data = await fetch(`${this.base_url}/${endpoint}`, {
      method: 'POST',
      headers: {
        // 'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: formData
    });
    return await data.json();
  },
  delete: async function (endpoint) {
    // formData.append("_method", "DELETE");
    const data = await fetch(`${this.base_url}/${endpoint}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });
    return await data.json();
  }
};
