import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap'
import TabArchives from 'react-bootstrap/Tab';
import TabsTabArchives from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { TextField } from '@mui/material';
import Form from 'react-bootstrap/Form';
import APIService from '../../../../APIService';
import swal from 'sweetalert';

export default function Archives() {

  const archiveTypes = [
    "Daily Orders",
    "General Order",
    "Tariff Orders",
    "ARR Orders",
    "Truning up Orders",
    "ARR Petitions",
    "Other Petitions",
  ];

  const [archives, setArchives] = useState([]);
  const [archive, setArchive] = useState("");
  const [archiveType, setArchiveType] = useState("");
  const [addedOn, setAddedOn] = useState("");
  const [fileUpload, setFileUpload] = useState("");

  const [archivesId, setArchivesId] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const [licensees, setLicensees] = useState([]);
  const [selectedLicensee, setSelectedLicensee] = useState('');

  useEffect(() => {
    APIService.get('licensees').then((response) => {
      setLicensees(response.data);
    })
  }, [])

  useEffect(() => {
    APIService.get('archives').then((response) => {
      setArchives(response.data);
    });
  }, []);

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFileUpload(e.target.files[0]);
    }
  }

  const handleEdit = (itemId, archive, archiveType, addedOn, licensee_id,) => {
    setArchivesId(itemId);
    setIsEditing(true);
    setArchive(archive);
    setArchiveType(archiveType);
    setAddedOn(addedOn);
    setSelectedLicensee(licensee_id);
  };

  const handleDelete = (itemId) => {
    // e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "You want to delete this item?",
      icon: "warning",
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        APIService.delete(`archives/${itemId}`).then((response) => {
          swal("Success", response.message, "success", {
            buttons: false,
            timer: 2000,
          }).then((value) => {
            window.location.href = "/archives";
          });
        });
      }
    });
  };


  const handleSubmit = async (e) => {

    e.preventDefault();

    const formData = new FormData();

    formData.append("order", archive);
    formData.append("option", archiveType);
    formData.append("added_on", addedOn);
    formData.append("file_upload", fileUpload);
    formData.append("licensee_id", selectedLicensee)

    if (isEditing) {
      var response = await APIService.put(formData, `archives/${archivesId}`);
    } else {
      var response = await APIService.post(formData, "archives");
    }

    if (response.success) {

      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).then((value) => {
        window.location.href = "/archives";
      });
    } else {

      swal("Failed", response.message, "error");
    }
  }

  return (
    <div>
      <Col md={12} >
        <h2 className="organisation-head">Archives</h2>

        <form noValidate onSubmit={handleSubmit}>
          <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
            <Form.Label column sm="2" id="form-labels">
              Select Order
            </Form.Label>
            <Col sm="10">
              <Form.Select
                aria-label="Default select example"
                value={archiveType}
                onChange={e => setArchiveType(e.target.value)}
              >
                <option value="" disabled>Select</option>
                <option value="Daily Orders">Daily Orders</option>
                <option value="General Order">General Order</option>
                <option value="Tariff Orders">Tariff Orders</option>
                <option value="ARR Orders">ARR Orders</option>
                <option value="Truning up Orders">Truning up Orders</option>
                <option value="ARR Petitions">ARR Petitions</option>
                <option value="Other Petitions">Other Petitions</option>
              </Form.Select>
            </Col>
          </Form.Group>


          <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
            <Form.Label column sm="2" id="form-labels">
              Select Licensees
            </Form.Label>
            <Col sm="10">
              <Form.Select
                aria-label="Default select example"
                value={selectedLicensee}
                onChange={e => setSelectedLicensee(e.target.value)}
              >

                <option value="" disabled>Select an Option</option>
                {licensees.map((licensee, index) => (
                  <option value={licensee.id} key={index}>
                    {licensee.heading}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Form.Group>



          <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
            <Form.Label column sm="2" id="form-labels">
              Order
            </Form.Label>
            <Col sm="10">
              <Form.Control as="textarea" rows={3} onChange={e => setArchive(e.target.value)} value={archive} />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
            <Form.Label column sm="2" id="form-labels">
              Upload file
            </Form.Label>
            <Col sm="10">
              <TextField name="upload-photo" type="file" onChange={handleFileChange} />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
            <Form.Label column sm="2" id="form-labels">
              Date
            </Form.Label>
            <Col sm="10">
              <Form.Control type="date" placeholder="Date" onChange={e => setAddedOn(e.target.value)} value={addedOn} />
            </Col>
          </Form.Group>

          <Button id="button" style={{ marginTop: 20 }} variant="primary" type="submmit">Submit</Button>{' '}

        </form>

        <TabsTabArchives
          defaultActiveKey={archiveTypes[0]}
          transition={false}
          id="noanim-tab-example"
          className="table-archives"
        >
          {archiveTypes.map((type, index) => (
            <TabArchives eventKey={type} title={type} key={"tab_" + index}>
              <div className="archive-scroll">
                <Table striped bordered hover variant="light">
                  <thead>
                    <tr>
                      <th className='table-no-aling'>Sr.No.</th>
                      <th>Order</th>
                      <th>Date</th>
                      <th>Upload/Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {archives
                      .filter(archive => archive.option === type)
                      .map((archive, index) => (
                        <tr key={archive.id}>
                          <td>{index + 1}</td>
                          <td>{archive.order}</td>
                          <td>{archive.added_on}</td>
                          <td >
                            <Button variant="primary"
                              onClick={() => handleEdit(archive.id, archive.order, archive.option, archive.added_on, archive.licensee_id)}
                            >Edit</Button>
                          </td>
                          <td >
                            <Button variant="primary"
                              onClick={() => handleDelete(archive.id)}
                            >Delete</Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </TabArchives>
          ))}
        </TabsTabArchives>

      </Col>
    </div>
  )
}
