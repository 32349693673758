import React, { useState, useEffect } from 'react';
import { Grid, TextField } from '@mui/material';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import APIService from '../../../../APIService';
import swal from 'sweetalert';
import { useParams } from 'react-router';

export default function EventsPhoto() {

  const params = useParams();
  const thumbnailId = params.id;

  const [events, setEvents] = useState([]);
  const [caption, setCaption] = useState("");
  const [fileUpload, setFileUpload] = useState("");

  const [eventId, setEventId] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    APIService.get('event_thumbnail/' + thumbnailId).then((response) => {
      setEvents(response.data);
    })
  }, []);

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFileUpload(e.target.files[0]);
    }
  }

  const handleEdit = (itemId, caption, description) => {
    setEventId(itemId);
    setIsEditing(true);
    setCaption(caption);
    // setDescription(description)
  }

  const handleDelete = (itemId) => {

    // e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "You want to delete this item?",
      icon: "warning",
      dangerMode: true,
    })
      .then(willDelete => {
        if (willDelete) {
          APIService.delete(`event/${itemId}`)
            .then(response => {
              swal("Success", response.message, "success", {
                buttons: false,
                timer: 2000,
              }).then((value) => {
                window.location.href = "/events/" + thumbnailId;
              });
            });
        }
      });
  }
  const handleSubmit = async (e) => {

    e.preventDefault();
    const formData = new FormData();

    formData.append("caption", caption);
    formData.append("thumbnail_id", thumbnailId);
    // formData.append("description", description);
    formData.append("image_upload", fileUpload);

    if (isEditing) {

      var response = await APIService.put(formData, `event/${eventId}`);
    } else {

      var response = await APIService.post(formData, 'event');
    }

    if (response.success) {

      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).then((value) => {

        window.location.href = "/events/" + thumbnailId;
      });
    } else {

      swal("Failed", response.message, "error");
    }
  }

  return (
    <div>

      <form noValidate onSubmit={handleSubmit}>
        <h3 className='mt-4'>Photo Upload (Events)</h3>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Caption</p>
            <TextField
              name="Caption"
              label="Caption"
              variant="outlined"
              fullWidth
              value={caption}
              onChange={e => setCaption(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} style={{ marginTop: 30 }} >
          <p id="labels">Upload Image</p>
          <TextField
            name="upload-photo"
            type="file"
            onChange={handleFileChange}
          />
        </Grid>
        {/* <Grid item xs={12} sm={6} lg={12} md={8} style={{ marginTop: 30 }}>
          <p id="labels">Decription</p>
          <TextField
            fullWidth
            id="outlined-multiline-static"
            label="Decription"
            multiline
            rows={3}
          />
        </Grid> */}
        <Button style={{ marginTop: 20 }} variant="primary" type="submit">Upload</Button>{' '}
      </form>

      <h3>Photo List</h3>

      <Table responsive="sm mt-5">
        <thead>
          <tr>
            <th>Sr.No</th>
            <th>Photo Heading</th>
            <th>Image</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {events.map((event, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{event.caption}</td>
              <td>{event.description}</td>
              <td><button className='tablebutton'
                onClick={() => handleEdit(event.id, event.caption)}
              >Edit</button></td>
              <td><button className='tablebutton'
                onClick={() => handleDelete(event.id)}
              >Delete</button></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
